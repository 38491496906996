import React from 'react';
import { connect } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import { setUnitInfowindow } from 'reducers/MapReducer';
import UnitMarker from './UnitMarker';
import UnitTooltip from 'components/UnitTooltip';

const positionOverlayView = (width, height) => ({ x: -width / 2, y: -height });

const MarkersUnits = (props) => {
  const { colors, resources, onUnitClick, coords, units } = props;

  const renderMarker = (unit, coords) => {
    const { ptsUnitID } = unit;
    let Resources = [];
    if (resources && resources[ptsUnitID]) Resources = resources[ptsUnitID];
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        key={unit.ptsUnitID}
        position={coords}
        getPixelPositionOffset={positionOverlayView}>
        <UnitTooltip unit={unit} Resources={Resources} coords={coords}>
          <UnitMarker colors={colors} unit={unit} onUnitClick={onUnitClick} />
        </UnitTooltip>
      </OverlayView>
    );
  };

  if (units.length > 0) {
    return units
      .filter((u) => coords[u.ptsUnitID])
      .map((u) => renderMarker(u, coords[u.ptsUnitID]));
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  units: state.units,
  colors: state.config.colorPalette.Units,
  resources: state.unitResources,
});

export default connect(mapStateToProps, { setUnitInfowindow })(MarkersUnits);
