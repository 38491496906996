import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { showReport, showEditPerson, showEditPlace } from 'reducers/DialogsReducer';
import { searchEmployees } from 'reducers/SearchReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import { Alert } from '@material-ui/lab';
import TextField2 from 'components/TextField2';
import Button from '@material-ui/core/Button';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 8,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const defaultColumns = [
  'PlaceName',
  'FullName',
  'Position',
  'Supervisor',
  {
    field: 'Hired',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Terminated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

function Employee(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [rawRows, setRawRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [employeeColumns, setEmployeeColumns] = useState(defaultColumns);
  const permissions = getFullPermissions('globals', 'Employees', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (rows.length !== 0 && rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (offset = 0, mergeRows = false) => {
    if (text.length < 3) return;
    setLoaded(false);
    searchEmployees(text, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length && setRowCount(result[0].Count);
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewBadge = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    const { ptsPersonID, ptsPlaceID, Username } = item;
    props.showReport({ id: 136, data: { ptsPersonID, ptsPlaceID, Username }, title: 'Badge' });
  };

  const viewReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    const { ptsPersonID, ptsPlaceID } = item;
    props.showReport({ id: 120, data: { ptsPersonID, ptsPlaceID }, title: 'Employee Details' });
  };

  const showDetailsMenu = (ev) => {
    setViewMenuAnchor(ev.currentTarget);
  };

  const closeDetailsMenu = () => {
    setViewMenuAnchor(null);
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const handlePersonClick = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPersonID } = item;
    props.showEditPerson(ptsPersonID);
  };

  const handlePlaceClick = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPlaceID } = item;
    props.showEditPlace(ptsPlaceID);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="View details">
          <span>
            <Fab
              onClick={showDetailsMenu}
              size="small"
              color="secondary"
              disabled={typeof selection !== 'number'}>
              <InfoIcon />
            </Fab>
          </span>
        </Tooltip>
        <Menu
          anchorEl={viewMenuAnchor}
          keepMounted
          open={Boolean(viewMenuAnchor)}
          onClose={closeDetailsMenu}>
          <MenuItem onClick={viewBadge}>Badge</MenuItem>
          <MenuItem onClick={viewReport}>Selected</MenuItem>
        </Menu>
        {!!selection && (
          <>
            <Tooltip title="View Person">
              <Fab size="small" color="secondary" onClick={handlePersonClick}>
                <PersonIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="View Place">
              <Fab size="small" color="secondary" onClick={handlePlaceClick}>
                <PlaceIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  const renderSaveBtn = () => {
    const disabled = text.length < 3;
    return (
      <div>
        <Button onClick={() => search()} variant="outlined" disabled={disabled}>
          Search
        </Button>
      </div>
    );
  };

  return (
    <div>
      <h5>Employees</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          {renderSearch()}
          {renderSaveBtn()}
        </div>
        {renderActions()}
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Employees"
        columns={employeeColumns}
        setColumns={setEmployeeColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewReport}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showEditPerson,
  showEditPlace,
})(Employee);
