import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, { gridStyle, Row, RowInner, ColCard } from 'utils/formStyles';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import PersonLookup2 from 'components/PersonLookup2';
import PlaceLookup from 'components/PlaceLookup';
import { editUnit, getUnit } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  note: gridStyle(200, 2000),
}));

function UnitEdit(props) {
  const classes = useStyles();
  const { dictionary, ptsUnitID, zones } = props;
  const { Agencies, Division } = dictionary;
  const ContactTypes = [{ Code: 'Person' }, { Code: 'Place' }];
  const [unit, setUnit] = useState('');
  const [agency, setAgency] = useState(null);
  const [division, setDivision] = useState(null);
  const [zone, setZone] = useState(null);
  const [contactType, setContactType] = useState(ContactTypes[0]);
  const [person, setPerson] = useState(null);
  const [place, setPlace] = useState(null);
  const [note, setNote] = useState('');
  const [personId, setPersonId] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);

  const search = async () => {
    const result = await getUnit(ptsUnitID);
    processInitialData(result);
  };

  const processInitialData = (data) => {
    const { Unit, AgencyID, Division: curDivision, Zone, ContactType, ptsContactID, Notes } = data;
    setUnit(Unit);
    const agency = Agencies.find((a) => a.AgencyID === AgencyID);
    setAgency(agency || null);
    const division = Division.find((d) => d.Code === curDivision);
    setDivision(division || null);
    const filteredZones = zones.filter((z) => z.AgencyID === agency?.AgencyID);
    const zone = filteredZones.find((z) => z.ZoneCode === Zone);
    setZone(zone || null);
    const contactType = ContactTypes.find((c) => c.Code === ContactType);
    setContactType(contactType || null);
    setNote(Notes || '');
    if (typeof ptsContactID === 'number') {
      ContactType === 'Person' ? setPersonId(ptsContactID) : setPlaceId(ptsContactID);
    }
  };

  const save = async () => {
    const dataObj = {
      Unit: unit,
      AgencyID: agency ? agency.AgencyID : null,
      Division: division ? division.Code : null,
      Zone: zone ? zone.ZoneCode : null,
      ContactType: contactType ? contactType.Code : null,
      ptsContactID: getContactId(),
      Notes: note,
    };
    try {
      await editUnit(ptsUnitID, dataObj);
      props.notifyDataUpdate({ type: 'edit-unit', data: { ptsUnitID } });
      //props.notify('Unit updated!', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const getContactId = () => {
    if (!contactType) return null;
    if (contactType.Code == 'Person') return person ? person.ptsPersonID : null;
    return place ? place.ptsPlaceID : null;
  };

  const renderSaveBtn = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!unit}>
          <SaveIcon /> Save
        </Button>
      </>
    );
  };

  const renderUnit = () => {
    const onChange = (ev, val) => setUnit(val);
    return (
      <TextField2 className={classes.item} label="Unit" value={unit} onChange={onChange} max={25} />
    );
  };

  const renderAgency = () => {
    const onChange = (ev, val) => {
      setAgency(val);
      setZone(null);
    };
    return (
      <Dictionary
        options="Agencies"
        className={classes.item}
        onChange={onChange}
        value={agency}
        label="Agency"
      />
    );
  };

  const renderDivision = () => {
    const onChange = (ev, val) => setDivision(val);
    return (
      <Dictionary
        options="Division"
        className={classes.item}
        onChange={onChange}
        value={division}
        label="Division"
        editingForm={true}
      />
    );
  };

  const renderZone = () => {
    const onChange = (ev, val) => setZone(val);
    const filteredZones = zones.filter((z) => z.AgencyID === agency?.AgencyID);
    return (
      <Autocomplete2
        options={agency ? filteredZones : zones}
        className={classes.item}
        onChange={onChange}
        value={zone}
        label="Zone"
        getOptionSelected={(option, value) => option.ZoneCode === value.ZoneCode}
        getOptionLabel={(option) => (option.ZoneCode ? option.ZoneCode : '')}
        renderOption={(option) => (
          <span>
            {option.ZoneCode} - {option.Description} - {option.AgencyID}
          </span>
        )}
      />
    );
  };

  const renderPrimaryContactType = () => {
    const onChange = (ev, val) => setContactType(val);
    return (
      <Autocomplete2
        options={ContactTypes}
        className={classes.item}
        onChange={onChange}
        value={contactType}
        label="Primary Contact Type"
      />
    );
  };

  const renderPerson = () => {
    const handlePersonChange = (value) => setPerson(value);
    return (
      <PersonLookup2
        className={classes.item}
        onPersonChange={handlePersonChange}
        label="Person"
        ptsPersonID={personId}
      />
    );
  };

  const renderPlace = () => {
    const handleChange = (value) => setPlace(value);
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Place"
        ptsPlaceID={placeId}
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNote(val);
    return (
      <TextField2
        className={classes.note}
        label="Note"
        value={note}
        onChange={onChange}
        max={8000}
        rows={5}
        multiline
      />
    );
  };

  return (
    <div>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderUnit()}
            {renderAgency()}
          </RowInner>
          <RowInner>
            {renderDivision()}
            {renderZone()}
          </RowInner>
          <RowInner>
            {renderPrimaryContactType()}
            {contactType?.Code == 'Person' && renderPerson()}
            {contactType?.Code == 'Place' && renderPlace()}
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
      {renderSaveBtn()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    zones: state.zones,
  };
};

export default connect(mapStateToProps, { notifyDataUpdate, notify, handleError })(UnitEdit);
