import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeTestingDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TimeTesting from './TimeTesting';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PermissionsTesting from './PermissionsTesting';
import TimeTesting2 from './TimeTesting2';
import PerformanceTesting from './PerformanceTesting';
import ExampleTesting from './ExampleTesting';
import E911Testing from './E911Testing';

const useStyles = makeStyles(() => ({
  content: {
    width: 1500,
    margin: '-20px -20px -16px',
    maxWidth: 'calc(100% + 40px)',
    height: 'calc(100vh - 180px)',
    boxSizing: 'border-box',
  },
}));

const TIME = 'TIME';
const TIME2 = 'TIME2';
const PERMISSIONS = 'PERMISSIONS';
const PERFORMANCE = 'PERFORMANCE';
const EXAPMLE = 'EXAPMLE';
const E911 = 'E911';

function TestingDialog(props) {
  const classes = useStyles();
  const [tab, setTab] = useState(E911);

  const close = () => {
    props.closeTestingDialog();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Testing" actions={renderActions()}>
      <div className={classes.content}>
        <Tabs value={tab} onChange={handleTabChange}>
          {/* <Tab label="Example" value={EXAPMLE} /> */}
          <Tab label="Time" value={TIME} />
          <Tab label="Time2" value={TIME2} />
          <Tab label="Permissions" value={PERMISSIONS} />
          <Tab label="Performance" value={PERFORMANCE} />
          <Tab label="E911" value={E911} />
        </Tabs>
        {tab === EXAPMLE && <ExampleTesting />}
        {tab === TIME && <TimeTesting />}
        {tab === TIME2 && <TimeTesting2 />}
        {tab === PERMISSIONS && <PermissionsTesting />}
        {tab === PERFORMANCE && <PerformanceTesting />}
        {tab === E911 && <E911Testing />}
      </div>
    </Dialog>
  );
}

export default connect(null, { closeTestingDialog })(TestingDialog);
