import React, { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import AgencyIcon from 'components/AgencyIcon';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import { displayTime, minsToHours, getPassedTime } from 'reducers/TimeReducer';
import IconButton from '@material-ui/core/IconButton';
import UnitStatusMenu from './UnitStatusMenu';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';
import Tooltip from 'components/Tooltip';
import { newUnitStatus, showUnitProps } from 'reducers/DialogsReducer';
import { mapAction } from 'reducers/MapReducer';
import UnitHotkeys from './UnitHotkeys';
import PopOver from 'components/PopOver';
import { setEventFilter } from 'reducers/EventSortingReducer';
import { getService } from 'reducers/service';
import UnitHistory from './UnitHistory';
import Spinner from 'components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import UnitTooltipInfo from './UnitInfoTooltip';

const iconWidth = 50;
const headerWidth = 185;
const statusWidth = 90;
const zoneWidth = 65;
const locationWidth = 150;
const timeWidth = 75;
const rightWidth = zoneWidth * 2;
const leftWidth = locationWidth + timeWidth + statusWidth + iconWidth + headerWidth - 10;

const useStyles = makeStyles((theme) => ({
  unit: {
    cursor: 'grab',
    display: 'flex',
    borderBottom: `1px solid ${theme.card.hr}`,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: theme.card.bg,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0 0',
    },
    '&:hover': {
      background: theme.card.bgHover,
      overflowX: 'auto',
      '& $menuBtn > div': {
        background: theme.card.bgHover,
      },
    },
    '&.unit-selected': {
      background: theme.colors.active,
      '& $menuBtn > div': {
        background: theme.colors.grey2,
      },
    },
    '&.unit-selected:hover': {
      '& $menuBtn > div': {
        background: theme.colors.grey2,
      },
    },
  },
  left: {
    width: `calc(100% - ${rightWidth}px)`,
    minWidth: leftWidth,
  },
  right: {
    width: `${rightWidth}px`,
    flexDirection: 'row-reverse',
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block',
    },
  },
  headerTd: {
    flex: `0 0 ${headerWidth}px`,
    width: headerWidth,
  },
  header: {
    '& h5': {
      fontSize: '0.85rem',
      marginBottom: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& svg': {
        fontSize: 20,
        marginTop: -4,
      },
    },
    '& p': {
      fontWeight: 500,
      opacity: 0.7,
      marginBottom: 0,
      fontSize: '0.8rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  statusTd: {
    flex: `0 0 ${statusWidth + 5}px`,
    width: statusWidth + 5,
    boxSizing: 'border-box',
  },
  menuBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& > div': {
      background: theme.card.bg,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
  },
  status: {
    textAlign: 'center',
    cursor: 'pointer',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
      width: statusWidth,
      minHeight: 20,
    },
  },
  eventTimes: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: '0.8rem',
    opacity: 0.75,
  },
  timeTd: {
    flex: `0 0 ${timeWidth}px`,
    width: timeWidth,
    textAlign: 'center',
  },
  locationTd: {
    fontSize: 12,
    textAlign: 'center',
    flex: `1 0 ${locationWidth}px`,
    width: locationWidth,
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    maxHeight: '3em',
  },
  stat: {
    display: 'inline-block',
    border: '1px solid #333',
    borderRadius: 4,
    padding: '1px 5px 0',
  },
  updateTime: {
    cursor: 'pointer',
  },
  passedTime: {
    border: 'none',
    background: 'none',
    padding: 0,
    width: 75,
    textAlign: 'center',
    color: theme.colors.grey6,
  },
  timeIsUp: {
    border: 'none',
    background: 'none',
    padding: 0,
    width: 75,
    textAlign: 'center',
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  zone: {
    textAlign: 'center',
    width: zoneWidth,
  },
  OutOfZone: {
    color: theme.palette.error.main,
  },
  noOfCalls: {
    minWidth: 20,
    '& > span': {
      background: theme.colors.grey2,
      textAlign: 'center',
      borderRadius: '50%',
      minWidth: 20,
      display: 'inline-block',
    },
    marginLeft: 8,
  },
  plate: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  zoneDetail: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  popOver: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: theme.tooltip.bg,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.tooltip.border}`,
    fontWeight: 400,
    boxShadow: `1px 1px 3px ${theme.tooltip.shadow}`,
  },
}));

function Unit(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statusHistory, setStatusHistory] = useState([]);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [numberOfCallsTooltip, setNumberOfCallsTooltip] = React.useState(false);
  const [timeTooltip, setTimeTooltip] = React.useState(false);
  const [unitTooltipRef, setUnitTooltipRef] = useState(false);
  const [loader, setLoader] = useState(false);
  const { unit, selected, ptsEventID, pinned, parent, dataPass, id, timeIsUp } = props;

  const {
    AgencyID,
    Unit,
    UnitStatus,
    LastActionOccurred,
    Flags,
    Events,
    OutOfZone,
    Division,
    UnitResources,
    Zone,
    Location,
    ptsUnitID,
    LastActionPlate,
    Plate,
    NoOfCalls,
  } = unit;

  const plate = Plate ? Plate : LastActionPlate;
  const mobile = Flags && Flags.trim() === 'M' ? true : false;
  const UnitColors = dataPass.current.colorPalette.Units;
  const statusColor = UnitColors[UnitStatus] ? UnitColors[UnitStatus] : UnitColors.Default;
  const statusMenuRef = useRef(null);
  const lastActionRef = useRef(LastActionOccurred);
  const passedTimeRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(handleTimeInterval, 30000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    lastActionRef.current = LastActionOccurred;
    handleTimeInterval();
    // eslint-disable-next-line
  }, [LastActionOccurred, UnitStatus]);

  const handleTimeInterval = () => {
    const passedTime = getPassedTime(lastActionRef.current);
    const el = passedTimeRef.current;
    el.value = minsToHours(passedTime);
  };

  const getUnitHistory = async () => {
    try {
      const service = getService('unit-history');
      const data = await service.find({ query: ptsUnitID });
      let history = [];
      if (data.length) {
        data.forEach((status) => {
          if (status.Unit === Unit) {
            history.push(status);
          }
        });
        history = sortObjArr(history, 'Occurred', 'ASC');
      }
      setStatusHistory(history);
      return history;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  const handleClickUnitHistory = async (currentTarget) => {
    try {
      setLoader(true);
      const history = await getUnitHistory();
      if (history.length) setTimeTooltip(currentTarget);
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoader(false);
  };

  const showUnitInfo = (ev) => {
    if (unitTooltipRef) return setUnitTooltipRef(null);
    setUnitTooltipRef(ev.currentTarget);
  };

  const renderIcon = () => {
    return (
      <div onClick={(ev) => showUnitInfo(ev)}>
        <AgencyIcon color={statusColor} AgencyID={AgencyID} />
        <PopOver anchorEl={unitTooltipRef} setAnchorEl={setUnitTooltipRef}>
          {Boolean(unitTooltipRef) && <UnitTooltipInfo unit={unit} />}
        </PopOver>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div>
        <div className={classes.headerTd}>
          <div className={classes.header}>
            <div className={classes.unitId}>
              <h5>
                {mobile && <DirectionsCarIcon />} {Unit} {Division && <span> | {Division}</span>}
              </h5>
              <p>{UnitResources}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTime = () => {
    return (
      <div className={classes.timeTd}>
        <div className={classes.updateTime}>
          <span onClick={(e) => handleClickUnitHistory(e.currentTarget)}>
            {displayTime(LastActionOccurred)}
          </span>
          <PopOver anchorEl={timeTooltip} setAnchorEl={setTimeTooltip}>
            <UnitHistory unit={unit} statusHistory={statusHistory} />
          </PopOver>
          <Tooltip title="Last action time [hrs:mins]">
            <input
              className={timeIsUp ? classes.timeIsUp : classes.passedTime}
              ref={passedTimeRef}
              type="text"
              readOnly
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderStatus = () => (
    <div className={classes.statusTd} onClick={showUnitStatusMenu} ref={statusMenuRef}>
      <div className={classes.status}>
        <i style={{ background: statusColor, color: '#000' }}>{UnitStatus}</i>
      </div>
    </div>
  );

  const renderNoOfCalls = () => (
    <div className={classes.noOfCalls}>
      {!!NoOfCalls && (
        <span onClick={(e) => setNumberOfCallsTooltip(e.currentTarget)}>{NoOfCalls}</span>
      )}
      <PopOver anchorEl={numberOfCallsTooltip} setAnchorEl={setNumberOfCallsTooltip}>
        <div className={classes.popOver}>
          <h4>Attached Events</h4>
          <table>
            <tbody>
              {Events?.map((event) => (
                <tr key={event.EventID}>
                  <td>{event.CallType}</td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => dispatch(setEventFilter(event.EventID))}>
                    {event.EventID}
                  </td>
                  <td>{event.Status}</td>
                  <td>{event.cla[0].FullAddressText}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PopOver>
    </div>
  );

  const dragStart = (ev) => {
    const { ctrlKey } = ev;
    let currentSelection = dataPass.current.selection ? [...dataPass.current.selection] : [];
    if (currentSelection.indexOf(ptsUnitID) === -1) {
      if (ctrlKey) {
        currentSelection.push(ptsUnitID);
        dataPass.current.setSelection && dataPass.current.setSelection(currentSelection);
      } else {
        currentSelection = [ptsUnitID];
        dataPass.current.setSelection && dataPass.current.setSelection([ptsUnitID]);
      }
    }
    const dragObj = {
      units: currentSelection,
      ptsEventID: ptsEventID !== undefined ? ptsEventID : null,
    };
    localStorage.setItem('dragNdrop', JSON.stringify(dragObj));
  };

  const dragEnd = () => {
    localStorage.removeItem('dragNdrop');
  };

  const showUnitStatusMenu = (ev) => {
    ev.stopPropagation();
    setStatusMenuOpen(true);
  };

  const closeUnitStatusMenu = () => {
    setStatusMenuOpen(false);
  };

  const showUnitProperties = (ev) => {
    ev.stopPropagation();
    dispatch(showUnitProps(unit.ptsUnitID));
  };

  const onUnitClick = async (ev) => {
    if (ev.detail === 1) {
      if (dataPass.current.onUnitClick) dataPass.current.onUnitClick(ev, unit);
      try {
        const service = getService('unit-coordinates');
        const data = await service.get(ptsUnitID, { query: { detailed: true } });
        if (data && data.lat && data.lng) {
          dispatch(mapAction({ type: 'zoom-and-center', lat: data.lat, lng: data.lng }));
        }
      } catch (err) {
        dispatch(handleError(err));
      }
    } else {
      onDblClick(ev);
    }
  };

  const onDblClick = (ev) => {
    dispatch(newUnitStatus(ptsUnitID));
  };

  const onPinClick = (ev) => {
    dataPass.current.togglePin(ev, id, unit.ptsUnitID);
  };

  return (
    <div>
      {loader && <Spinner />}
      <div
        className={clsx(
          classes.unit,
          'thin-horizontal-scrollbar',
          selected ? 'unit-selected' : null
        )}
        draggable="true"
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onClick={onUnitClick}>
        <div className={classes.left}>
          <div className={classes.icon}>{renderIcon()}</div>
          {renderHeader()}
          {renderStatus()}
          {renderNoOfCalls()}
          {renderTime()}
          <div className={classes.locationTd}>{Location}</div>
        </div>
        <div className={classes.right}>
          <div className={clsx(classes.zone, OutOfZone ? classes.OutOfZone : null)}>
            <small onClick={showUnitProperties}>Zone</small>
            <br />
            {Zone !== null ? (
              <div className={classes.zoneDetail}>
                <Tooltip title={Zone}>
                  <span>{Zone}</span>
                </Tooltip>
              </div>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className={classes.zone}>
            <small>Plate</small>
            <br />
            {plate === null || plate === undefined ? (
              <span>-</span>
            ) : (
              <Tooltip title={plate}>
                <span className={classes.plate}>{plate}</span>
              </Tooltip>
            )}
          </div>
        </div>
        {parent === 'unit-list' && (
          <IconButton onClick={(ev) => onPinClick(ev, id, ptsUnitID)}>
            {pinned && <AiFillPushpin />}
            {!pinned && <AiOutlinePushpin />}
          </IconButton>
        )}
      </div>
      {statusMenuOpen && (
        <UnitStatusMenu
          anchorEl={statusMenuRef.current}
          open={statusMenuOpen}
          onClose={closeUnitStatusMenu}
          unit={unit}
          ptsEventID={ptsEventID}
          noOfCalls={NoOfCalls}
        />
      )}
      {selected && <UnitHotkeys ptsEventID={ptsEventID} ptsUnitID={unit.ptsUnitID} />}
    </div>
  );
}

export default function UnitWrap(props) {
  const unitsNeedAttention = useSelector((state) => state.unitsNeedAttention);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const { unit, selected, pinned, parent, id } = props;
  useEffect(() => {
    const timeUp = unitsNeedAttention.indexOf(unit.ptsUnitID) !== -1;
    if (timeUp !== timeIsUp) setTimeIsUp(timeUp);
  }, [unitsNeedAttention]);

  // --------------------- TEST COMPONENT RENDERING -----------------------
  // console.log(Object.keys(props));
  // useEffect(() => console.log('Changed: ' + unit.Unit + ' unit'), [unit]);
  // useEffect(() => console.log('Changed: ' + unit.Unit + ' selected'), [selected]);
  // useEffect(() => console.log('Changed: ' + unit.Unit + ' pinned'), [pinned]);
  // useEffect(() => console.log('Changed: ' + unit.Unit + ' parent'), [parent]);
  // useEffect(() => console.log('Changed: ' + unit.Unit + ' id'), [id]);

  return useMemo(() => <Unit {...props} timeIsUp={timeIsUp} />, [
    unit,
    selected,
    pinned,
    parent,
    id,
    timeIsUp,
  ]);
}
