import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from 'components/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { getZoneNames, addZoneToLocation, delZoneFromLocation } from 'reducers/ZonesReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import settings from 'config/settings';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import Dictionary from 'components/Dictionary';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  zones: {
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  case: {
    padding: '10px 15px',
    position: 'relative',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& p': {
      fontSize: 13,
      marginBottom: 0,
    },
    '&:hover': {
      '& $zoneAction': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    '& h4': {
      fontSize: 16,
      minWidth: 130,
      marginBottom: '0.25em',
    },
    '& p': {
      fontSize: 14,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
  zoneAction: {
    position: 'absolute',
    right: 10,
    top: 5,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  autocomplete: {
    margin: '0 4px',
    maxWidth: '50%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
}));

function Zone(props) {
  const classes = useStyles();
  const { disableEdit, zone } = props;
  const { ZoneCode, Description, AgencyID } = zone;

  const { Delete } = getFullPermissions('cad', 'Events', 'any');

  const deleteZone = () => {
    if (!window.confirm(`Are you sure that you want to delete the ${ZoneCode} zone?`)) {
      return;
    }
    props.deleteZone(props.zone);
  };

  return (
    <div className={classes.case}>
      <div className={classes.header}>
        <div className={classes.left}>
          <h4>{ZoneCode}</h4>
          <p>
            {Description} ({AgencyID})
          </p>
        </div>
        <div></div>
      </div>
      {!disableEdit && (
        <div className={classes.zoneAction}>
          <Tooltip title="Delete disposition">
            <span>
              <IconButton
                size="small"
                component="span"
                color="primary"
                onClick={deleteZone}
                disabled={!Delete}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

function ZonesTab(props) {
  const { sortOption, ptsEventLocationID, ptsEventID, disableEdit } = props;
  const [sortedZones, setSortedZones] = useState([]);
  const [edited, setEdited] = useState(true);
  const [selectedZone, setSlectedZone] = useState(null);
  const [agency, setAgency] = useState(null);
  const [zones, setZones] = useState([]);
  const [agencyZones, setAgencyZones] = useState([]);
  const classes = useStyles();

  const { Create } = getFullPermissions('cad', 'Events', 'any');

  useEffect(() => {
    loadZones();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!props.zones) return;
    setSortedZones(sortObjArr(props.zones, 'ZoneCode', sortOption));
    // eslint-disable-next-line
  }, [props.zones, sortOption]);

  useEffect(() => {
    if (zones.length === 0 || !agency) return;
    setAgencyZones(
      zones
        .filter((zone) => zone.AgencyID === agency.AgencyID)
        .filter((zone) => !sortedZones.find((z) => z.ZoneCode === zone.ZoneCode))
    );
    // eslint-disable-next-line
  }, [zones, agency]);

  const loadZones = () => {
    getZoneNames()
      .then((zones) => {
        setZones(sortObjArr(zones, 'ZoneCode', sortOption));
      })
      .catch((error) => props.handleError(error, 'Error, there was a problem with loading zones'));
  };

  const onSave = () => {
    addZoneToLocation(selectedZone.ZoneCode, ptsEventLocationID)
      .then(() => {
        deselect();
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, zone not added'))
      .finally(() => {
        setEdited(true);
      });
  };

  const deselect = () => {
    setAgency(null);
    setSlectedZone(null);
    setAgencyZones([]);
  };

  const onDiscard = () => {
    setEdited(false);
    deselect();
  };

  const onAddZone = () => {
    setEdited(true);
  };

  const deleteZone = (zone) => {
    delZoneFromLocation(zone.ZoneCode, ptsEventLocationID)
      .then(() => {
        //props.notify('Zone removed', 'info');
      })
      .catch((error) => props.handleError(error, 'Error, zone not removed'))
      .finally(() => {
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      });
  };

  const renderAddZone = () => {
    return (
      <div className={classes.form}>
        <Dictionary
          options="Agencies"
          className={classes.autocomplete}
          onChange={(ev, val) => setAgency(val)}
          value={agency}
          label="Agency"
          compact="true"
        />
        <Autocomplete
          value={selectedZone}
          options={agencyZones}
          onChange={(ev, val) => setSlectedZone(val)}
          getOptionLabel={(option) =>
            option.Description ? `${option.ZoneCode} - ${option.Description}` : option.ZoneCode
          }
          renderInput={(params) => <TextField {...params} variant="outlined" label="Zone" />}
          size="small"
          className={classes.autocomplete}
          disabled={!agency}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!edited && (
          <Tooltip title="Add zone">
            <IconButton color="primary" size="small" onClick={onAddZone}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {edited && selectedZone !== null && (
          <Tooltip title="Save changes">
            <span>
              <IconButton color="primary" size="small" onClick={onSave} disabled={!Create}>
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {edited && selectedZone === null && (
          <IconButton color="primary" size="small" disabled>
            <SaveIcon />
          </IconButton>
        )}

        {edited && (
          <Tooltip title="Discard changes">
            <IconButton color="primary" size="small" onClick={onDiscard}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderZones = () => {
    return (
      <>
        {sortedZones.map((zone) => (
          <Zone key={zone.ZoneCode} zone={zone} deleteZone={deleteZone} disableEdit={disableEdit} />
        ))}
      </>
    );
  };

  if (!sortedZones) return '';
  return (
    <div className={classes.zones}>
      {!disableEdit && renderActions()}
      {!disableEdit && edited && renderAddZone()}
      {renderZones()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  notify,
  handleError,
  notifyDataUpdate,
})(ZonesTab);
