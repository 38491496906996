const defaultMenuOptions = {
  Units: {
    seq: 1,
  },
  Dispositions: {
    sort: 'ASC',
    seq: 2,
  },
  Notes: {
    sort: 'ASC',
    seq: 3,
  },
  Attachments: {
    sort: 'ASC',
    seq: 4,
  },
  History: {
    sort: 'ASC',
    seq: 5,
  },
  'Case IDs': {
    sort: 'ASC',
    seq: 6,
  },
  Parties: {
    sort: 'ASC',
    seq: 7,
  },
  Routing: {
    sort: 'ASC',
    seq: 8,
  },
  Zones: {
    sort: 'ASC',
    seq: 7,
  },
  Notifications: {
    sort: null,
    seq: 10,
  },
  Locations: {
    sort: 'ASC',
    seq: 11,
  },
  Vehicles: {
    sort: 'ASC',
    seq: 12,
  },
  Times: {
    sort: 'ASC',
    seq: 13,
  },
  Bulletins: {
    sort: 'ASC',
    seq: 14,
  },
};

export default defaultMenuOptions;
