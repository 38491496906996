import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { getTotalNumberOfSops, searchSops } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { showReport, addSop, editSop } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import { Redirect } from 'react-router-dom';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'Title',
  'DispatcherMessage',
  'AlertMessage',
  {
    field: 'IsAlertMessageUsed',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'ShowPopupNotification',
    renderCell: bool,
    format: 'bool',
  },
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

function Sop(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [sopColumns, setSopColumns] = useState(defaultColumns);
  const permissions = getFullPermissions('globals', 'SOPs', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'update-sop') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  useEffect(() => {
    (async () => {
      const totalRows = await getTotalNumberOfSops();
      totalRows.length && setRowCount(totalRows[0].count);
    })();
  }, []);

  const search = async (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchSops(offset, limit)
      .then((result) => {
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows) setRows((prevState) => [...prevState, ...processData(result)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsSOPID,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 48, data: { ptsSOPID: item.ptsSOPID }, title: 'SOP Details' });
  };

  const addSop = () => {
    props.addSop();
  };

  const editSop = () => {
    props.editSop({ ptsSOPID: selection });
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  return (
    <div>
      <h5>SOP Records</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={sopColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Add SOP">
            <Fab className="ml-2" size="small" color="secondary" onClick={addSop}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="Edit SOP">
                <Fab className="ml-2" size="small" color="secondary" onClick={editSop}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="SOP Records"
        columns={sopColumns}
        setColumns={setSopColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  addSop,
  editSop,
})(Sop);
