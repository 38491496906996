import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import { searchAddresses } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showAddressDialog } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import AddressMerge from './AddressMerge';
import Checkbox2 from 'components/Checkbox2';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from '@material-ui/core';
import TextField2 from 'components/TextField2';
import { Alert } from '@material-ui/lab';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
  checkbox: {
    marginTop: 8,
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const defaultColumns = [
  'AddressID',
  'FullAddress',
  'AddressNumber',
  { field: 'PreDirection', headerName: 'Predirectional' },
  'StreetName',
  'StreetType',
  { field: 'PostDirection', headerName: 'Postdirectional' },
  'UnitType',
  'UnitIdentifier',
  'City',
  'State',
  'PostalCode',
  { field: 'PostalCodeExtension', headerName: 'Extension' },
  'Country',
  'AddressCategory',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const noDeletedColumns = [
  'AddressID',
  'FullAddress',
  'AddressNumber',
  { field: 'PreDirection', headerName: 'Predirectional' },
  'StreetName',
  'StreetType',
  { field: 'PostDirection', headerName: 'Postdirectional' },
  'UnitType',
  'UnitIdentifier',
  'City',
  'State',
  'PostalCode',
  { field: 'PostalCodeExtension', headerName: 'Extension' },
  'Country',
  'AddressCategory',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

function Address(props) {
  const classes = useStyles();
  const permissions = getFullPermissions('globals', 'Addresses', 'any');
  const userColumns = permissions.ViewDeleted ? defaultColumns : noDeletedColumns;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [addressColumns, setAddressColumns] = useState(userColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const [showInEventLookup, setShowInEventLookup] = useState(false);
  const [ViewDeleted, setViewDeleted] = useState(false);
  const [text, setText] = useState('');
  const dataUpdate = useSelector((state) => state.dataUpdate);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type == 'Address') {
      search();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length !== 0 && rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (offset = 0, mergeRows = false) => {
    if (text.length < 3) return;
    setLoaded(false);
    searchAddresses(text, ViewDeleted, showInEventLookup, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length && setRowCount(result[0].Count);
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 40,
      data: { ptsAddressID: item.ptsAddressID },
      title: 'Address Details',
    });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsAddressID } = item;
    props.showAddAttachment({ id: ptsAddressID, type: 'Address' });
  };

  const add = () => props.showAddressDialog(true);

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsAddressID } = item;
    props.showAddressDialog({ ptsAddressID });
  };

  const copyAddress = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsAddressID } = item;
    props.showAddressDialog({ ptsAddressID, copyAddress: true });
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };
  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };
  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Addresses History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          {renderSearch()}
          <div>
            <Button disabled={text.length < 3} variant="outlined" onClick={() => search()}>
              Search
            </Button>
          </div>
          {permissions.ViewDeleted && (
            <Checkbox2
              label="Show Deleted"
              checked={ViewDeleted}
              onChange={(ev, val) => setViewDeleted(val)}
              className={classes.checkbox}
            />
          )}
          <Checkbox2
            label="Show in Event Lookup"
            checked={showInEventLookup}
            onChange={(ev, val) => setShowInEventLookup(val)}
            className={classes.checkbox}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {permissions.Create && (
            <Tooltip title="Add Address">
              <Fab className="ml-2" size="small" color="secondary" onClick={add}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
          {selection !== null && (
            <>
              {permissions.Edit && (
                <>
                  <Tooltip title="Edit address">
                    <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Add attachment">
                    <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                      <AttachFileIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Merge address">
                    <Fab
                      className="ml-2"
                      color="secondary"
                      size="small"
                      onClick={() => setShowMerge(true)}>
                      <MergeTypeIcon />
                    </Fab>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Copy Address">
                <Fab className="ml-2" size="small" color="secondary" onClick={copyAddress}>
                  <FileCopyIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Addresses History"
        columns={addressColumns}
        setColumns={setAddressColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
      {showMerge && (
        <AddressMerge
          rows={rows}
          sourceID={selection}
          close={() => setShowMerge(false)}
          columns={addressColumns}
        />
      )}
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showAddAttachment,
  showAddressDialog,
})(Address);
