import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import UnitsGroup from './UnitsGroup';
import Unit from './Unit';
import AgencyIcon from 'components/AgencyIcon';
import { sortObjArr, areObjEqual } from 'utils/functions';
import { getRowCountByStatus, setUnitStatus } from 'reducers/UnitStatusReducer';
import Card from '@material-ui/core/Card';
import { addMultiSelectableUnitDialog, newUnitStatus } from 'reducers/DialogsReducer';
import { filterArr } from 'utils/functions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updateUnitsResources } from 'reducers/UnitResourcesReducer';
import { updateUnitGroups } from 'reducers/UnitGroupsReducer';
import { Tooltip } from '@material-ui/core';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import globals from 'components/globals';
import {
  setSelectedUnits,
  setSelectedUnitFromEvent,
  setSelectedEvents,
} from 'reducers/SelectionReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    marginBottom: 40,
  },
  summaryContent: {
    boxSizing: 'border-box',
    width: '100%',
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sumContentLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  sumContentRight: {
    display: 'flex',
  },
  agencyIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
    fill: theme.palette.primary.main,
  },
  badge: {
    background: theme.palette.secondary.main,
    //background: (props) => props.offDutyColor,
    border: `2px solid #fff`,
    borderRadius: 20,
    display: 'inline-block',
    color: '#fff',
    fontWeight: 500,
    minWidth: '1.5rem',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: 5,
  },
  offDutyBadge: {
    //background: theme.palette.secondary.main,
    background: (props) => props.offDutyColor,
    border: `2px solid #fff`,
    borderRadius: 20,
    display: 'inline-block',
    color: '#fff',
    fontWeight: 500,
    minWidth: '1.5rem',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: 5,
  },
  standByBadge: {
    //background: theme.palette.secondary.main,
    background: (props) => props.standByColor,
    border: `2px solid #fff`,
    borderRadius: 20,
    display: 'inline-block',
    color: '#fff',
    fontWeight: 500,
    minWidth: '1.5rem',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: 5,
  },
  inServiceBadge: {
    //background: theme.palette.secondary.main,
    background: (props) => props.inServiceColor,
    border: `2px solid #fff`,
    borderRadius: 20,
    display: 'inline-block',
    color: '#fff',
    fontWeight: 500,
    minWidth: '1.5rem',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: 5,
  },
  draggingOver: {
    background: theme.palette.secondary.main,
    boxShadow: `0 0 0 5px ${theme.palette.secondary.main}`,
    marginBottom: 40,
  },
  dragHandle: {
    cursor: 'move !important',
    position: 'absolute',
    display: 'flex',
    opacity: 0,
    transition: 'opacity 0.3s',
    top: 1,
    left: 3,
    right: 3,
    height: 7,
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 3,
    zIndex: 1,
    overflow: 'hidden',
    '& > i': {
      borderTop: `1px dotted ${theme.palette.primary.main}`,
    },
  },
  unitWrap: {
    position: 'relative',
    paddingBottom: 1,
    height: 50,
    '&:hover $dragHandle': {
      opacity: 1,
    },
  },
  dragHandleDisabled: {
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
}));

const preventUpdate = () =>
  Boolean(window.localStorage.getItem('dragNdrop') || globals.menuOpen || globals.windowOpen);

const filterCols = ['Division', 'Zone', 'Unit', 'UnitResources', 'AgencyID', 'UnitStatus'];
const CATEGORY_OUTSERVICE = 'outservice';
const CATEGORY_INSERVICE = 'inservice';
const CATEGORY_STANDBY = 'standby';

function UnitList(props) {
  const [groups, setGroups] = useState([]);
  const {
    unfoldSubject,
    colorPalette,
    options,
    showPinned,
    data,
    unitSort,
    unitGroups,
    unitAgencyFilter,
    unitGroupBy,
    unitSortBy,
    unitSortOrder,
    unitSeq,
    UnitActions,
  } = props;
  const dispatch = useDispatch();
  const { filter } = unitSort;
  const UnitColors = colorPalette.Units;

  const statusOutservice = getUnitCodeByCategory(CATEGORY_OUTSERVICE);
  const statusInservice = getUnitCodeByCategory(CATEGORY_INSERVICE);
  const statusStandby = getUnitCodeByCategory(CATEGORY_STANDBY);

  const offDutyColor = getStatusColor(statusOutservice);
  const standByColor = getStatusColor(statusStandby);
  const inServiceColor = getStatusColor(statusInservice);

  const classes = useStyles({ offDutyColor, standByColor, inServiceColor });
  const [prevGroupBy, setPrevGroupBy] = useState('agency');
  const [dragging, setDragging] = useState(false);
  const [dragged, setDragged] = useState(null);
  const [draggingStarted, setDraggingStarted] = useState(false);
  //const [selection, setSelection] = useState([]);
  const [pinned, setPinned] = useState([]); // [ptsUnitID]
  const [rowCounts, setRowCounts] = useState([]);
  const loseFocusRef = useRef();
  const hasSelectionRef = useRef();
  const draggingTimeRef = useRef();
  const updateTimeoutRef = useRef(0);
  const dataPass = useRef({});
  const selection = useSelector((state) => state.selection.unitSelection);
  //const lastChangedUnit = useRef();
  if (selection.length) {
    // Clear selection after dragging complete
    const isDragged = !!dragged;
    const isStored = !!localStorage.getItem('dragNdrop');
    if (isDragged && isStored && !draggingStarted) setDraggingStarted(true);
    if (isDragged && !isStored && draggingStarted) {
      dispatch(setSelectedUnits());
      //setSelection([]);
      setDragged(false);
      setDraggingStarted(false);
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', removeSelection);
    props.updateUnitsResources();
    return () => {
      document.body.removeEventListener('click', removeSelection);
      clearTimeout(updateTimeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getRowCountByStatus();
      setRowCounts(data);
    })();
  }, [data]);

  useEffect(() => {
    setPinned(unitSeq.map((s) => s.ptsUnitID));
    setGroupsData(unitSeq);
    // eslint-disable-next-line
  }, [data, unitGroupBy, unitSortBy, unitSortOrder, pinned.length]);

  const updateGroups = (groups) => {
    clearTimeout(updateTimeoutRef.current);
    if (preventUpdate()) {
      updateTimeoutRef.current = setTimeout(() => {
        updateGroups(groups);
      }, 250);
    } else {
      setGroups(groups);
    }
  };

  function getUnitCodeByCategory(category) {
    const action = UnitActions.filter(
      (action) => action.Category && action.Category.toLowerCase() === category
    );
    return action.length && action[0].Code;
  }

  function getStatusColor(status) {
    return UnitColors[status] ? UnitColors[status] : UnitColors.Default;
  }
  /* Reorder list used in drag and drop ordered list */
  const reorderDnd = (units, res) => {
    if (!units) return;
    const filteredUnits = getFilteredUnits(units);
    const from = res.source.index;
    const to = res.destination.index;
    const unitIdFrom = filteredUnits[from].ptsUnitID;
    const unitIdTo = filteredUnits[to].ptsUnitID;
    const fromIdx = units.findIndex((u) => u.ptsUnitID === unitIdFrom);
    const toIdx = units.findIndex((u) => u.ptsUnitID === unitIdTo);
    units.splice(toIdx, 0, units.splice(fromIdx, 1)[0]);
    return units;
  };

  const getGroupIdx = (key, id) => groups.findIndex((group) => group[key] === id);

  const pinToGroups = (type, id, ptsUnitID) => {
    const idx = getGroupIdx(type, id);
    if (idx === -1) return console.log('No group found !!!');
    const group = groups[idx];
    const data = group.units;
    const unit = data.find((user) => user.ptsUnitID === ptsUnitID);
    if (!unit) return console.log('No unit found !!!');
    const newData = data.filter((user) => user.ptsUnitID !== ptsUnitID);
    const groupPins = pinned.filter((ptsUnitID) =>
      newData.find((unit) => ptsUnitID === unit.ptsUnitID)
    );
    const position = groupPins.length;
    newData.splice(position, 0, unit);
    const newGroups = [...groups];
    newGroups[idx].units = newData;
    return newGroups;
  };

  const onPinnedDragEnd = (id) => (result) => {
    if (!result.destination) return;
    const groupByKey = getGroupByKey(prevGroupBy);
    const isGrupped = Boolean(groupByKey);
    if (isGrupped) {
      const idx = getGroupIdx(groupByKey, id);
      if (idx === -1) return console.log('No group found !!!');
      const units = groups[idx].units;
      if (!units) return console.log('No units found !!!');
      const reorderedUnits = reorderDnd(units, result);
      const newGroups = [...groups];
      newGroups[idx].units = reorderedUnits;
      updateGroups(newGroups);
      savePinned(pinned, newGroups);
    } else {
      const units = groups[0].units;
      if (!units) return console.log('No units found !!!');
      const reorderedUnits = reorderDnd(units, result);
      const newGroups = [{ units: reorderedUnits }];
      updateGroups(newGroups);
      savePinned(pinned, groups);
    }
  };

  const togglePin = (ev, id, ptsUnitID) => {
    let newPinned;
    ev.stopPropagation();
    let newGroups;
    const key = getGroupByKey(prevGroupBy);
    if (pinned.indexOf(ptsUnitID) === -1) {
      newPinned = [...pinned, ptsUnitID];
      if (prevGroupBy === 'none') {
        const data = groups[0].units;
        const unit = data.find((user) => user.ptsUnitID === ptsUnitID);
        if (!unit) return console.log('No unit found !!!');
        const newData = data.filter((user) => user.ptsUnitID !== ptsUnitID);
        const position = pinned.length;
        newData.splice(position, 0, unit);
        newGroups = [{ units: newData }];
      } else {
        newGroups = pinToGroups(key, id, ptsUnitID);
      }
      updateGroups(newGroups);
    } else {
      newPinned = pinned.filter((id) => id !== ptsUnitID);
    }
    savePinned(newPinned, newGroups ? newGroups : groups); // save seq and storage
    setPinned(newPinned);
  };

  const savePinned = (pinned, groups) => {
    const seq = [];
    groups.forEach((group) => {
      pinned.forEach((ptsUnitID) => {
        const idx = group.units.findIndex((unit) => unit.ptsUnitID === ptsUnitID);
        if (idx !== -1) seq.push({ idx, ptsUnitID });
      });
    });
    props.saveUserSetting('unitSeq', seq);
  };

  const renderUnit = (unit, idx, id) => {
    const { ptsUnitID } = unit;
    const pin = pinned.indexOf(ptsUnitID) !== -1;
    return (
      <Draggable key={ptsUnitID} draggableId={ptsUnitID + '-dnd-unit'} index={idx}>
        {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
          <div ref={innerRef} {...draggableProps}>
            <div className={classes.unitWrap}>
              <div
                className={pin ? classes.dragHandle : classes.dragHandleDisabled}
                {...dragHandleProps}>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
              </div>
              <Unit
                id={id}
                key={ptsUnitID}
                unit={unit}
                selected={selection.indexOf(ptsUnitID) !== -1}
                pinned={pin}
                parent="unit-list"
                dataPass={dataPass}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  // Units
  const unitList = (units, id) => {
    if (!units.length) return '';
    return (
      <>
        <div className={classes.units}>
          <DragDropContext onDragEnd={onPinnedDragEnd(id)}>
            <Droppable droppableId={`droppable-unit-${id}`}>
              {({ innerRef, droppableProps, placeholder }, snapshot) => (
                <div ref={innerRef} {...droppableProps}>
                  {units
                    .filter((u) => u.ptsUnitID && u.UnitStatus !== statusStandby)
                    .map((unit, idx) => renderUnit(unit, idx, id))}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  };

  const sortUnits = (units) => sortObjArr(units, unitSortBy, unitSortOrder);

  // Handle Unit Selection
  const onUnitClick = (ev, unit) => {
    clearTimeout(loseFocusRef.current);

    const ctrl = ev.ctrlKey;
    const shift = ev.shiftKey;
    const { ptsUnitID } = unit;
    let newSelection = [];
    if (!ctrl && !shift) {
      // only one selected
      dispatch(setSelectedUnitFromEvent());
      dispatch(setSelectedEvents());
      newSelection = selection.length === 1 && selection[0] === ptsUnitID ? [] : [ptsUnitID];
    } else if (ctrl) {
      // multiple selected
      const inSelection = selection.find((id) => id === ptsUnitID);
      if (inSelection) {
        // remove from selection
        newSelection = selection.filter((id) => id !== ptsUnitID);
      } else {
        newSelection = [...selection, ptsUnitID];
      }
    } else if (shift) {
      const sortedIDs = getSortedUnitIDs();
      const idx1 = getFirstSelectedIdx(sortedIDs);
      const idx2 = sortedIDs.indexOf(ptsUnitID);
      const idx = Math.min(idx1, idx2);
      const len = Math.abs(idx1 - idx2) + 1;
      newSelection = sortedIDs.splice(idx, len);
    }
    //setSelection(newSelection);
    dispatch(setSelectedUnits(newSelection));
  };

  const removeSelection = () => {
    if (!hasSelectionRef.current) return;
    clearTimeout(loseFocusRef.current);
    loseFocusRef.current = setTimeout(() => {
      //setSelection([]);
      dispatch(setSelectedUnits());
      hasSelectionRef.current = false;
    }, 50);
  };

  const getSortedUnitIDs = () => {
    const sortedUnitIds = [];
    groups.forEach(({ units }) => {
      units.forEach((unit) => {
        sortedUnitIds.push(unit.ptsUnitID);
      });
    });
    return sortedUnitIds;
  };

  const getFirstSelectedIdx = (sortedIDs) => {
    let firstSelected = null;
    sortedIDs.forEach((id) => {
      if (firstSelected === null && selection.indexOf(id) !== -1) {
        firstSelected = id;
      }
    });
    return sortedIDs.indexOf(firstSelected);
  };

  const getGroupByKey = (unitGroupBy) => {
    switch (unitGroupBy) {
      case 'none':
        return null;
      case 'agency':
        return 'AgencyID';
      case 'status':
        return 'UnitStatus';
      case 'zone':
        return 'Zone';
      case 'Division':
        return 'Division';
      default:
        return null;
    }
  };

  const reorderGroup1 = (data, seq) => {
    if (!data) return [];
    if (!seq.length) return sortUnits(data);
    const notPinnedUnits = data.filter((unit) => pinned.indexOf(unit.ptsUnitID) === -1);
    const pinnedUnits = data.filter((unit) => pinned.indexOf(unit.ptsUnitID) !== -1);
    const sorted = sortUnits(notPinnedUnits);
    const sortedSeq = sortObjArr(seq, 'idx', 'ASC');
    sortedSeq.forEach((s) => {
      const { idx, ptsUnitID } = s;
      const unitIdx = pinnedUnits.findIndex((unit) => unit.ptsUnitID === ptsUnitID);
      const unitArr = pinnedUnits.splice(unitIdx, 1);
      if (unitArr) {
        sorted.splice(idx, 0, unitArr[0]);
      }
    });
    pinnedUnits.forEach((unit) => sorted.push(unit));
    const cleaned = sorted.reduce((res, val) => (val ? res.concat([val]) : res), []);
    return cleaned;
  };

  const reorderGroup2 = (data, seq) => {
    if (!seq.length) return sortUnits(data);
    const key = getGroupByKey(unitGroupBy);
    const newGroups = [];
    const pinned = seq.map((s) => s.ptsUnitID);
    data.forEach((group, i) => {
      const pinnedUnits = group.units.filter((unit) => pinned.indexOf(unit.ptsUnitID) !== -1);
      const notPinnedUnits = group.units.filter((unit) => pinned.indexOf(unit.ptsUnitID) === -1);
      const sorted = sortUnits(notPinnedUnits);
      const sortedSeq = sortObjArr(seq, 'idx', 'ASC');
      sortedSeq.forEach((s) => {
        const { idx, ptsUnitID } = s;
        const unitIdx = pinnedUnits.findIndex((unit) => unit.ptsUnitID === ptsUnitID);
        if (unitIdx !== -1) {
          const unitArr = pinnedUnits.splice(unitIdx, 1);
          sorted.splice(idx, 0, unitArr[0]);
        }
      });
      pinnedUnits.forEach((unit) => sorted.push(unit));
      const cleaned = sorted.reduce((res, val) => (val ? res.concat([val]) : res), []);
      newGroups.push({ [key]: group[key], units: cleaned });
    });
    return newGroups;
  };

  const reorderGroup3 = (groups) => {
    const id = findCurGroupId();
    if (!unitGroups || !unitGroups[unitGroupBy]) return alphabeticSort(groups, id);
    const curGroupSeq = unitGroups[unitGroupBy];
    const result = [];
    for (const item of curGroupSeq) {
      const group = groups.find((g) => g[id] === item);
      if (group) result.push(group);
    }
    for (const item of groups) {
      const group = curGroupSeq.find((g) => g === item[id]);
      if (!group) result.push(item);
    }
    return result;
  };

  const alphabeticSort = (groups, id) => groups.sort((a, b) => (a[id] || '').localeCompare(b[id]));

  const setGroupsData = async (seq) => {
    if (unitGroupBy === 'none') {
      const newGroups = [{ units: reorderGroup1(data, seq) }];
      updateGroups(newGroups);
    } else {
      const key = getGroupByKey(unitGroupBy);
      const groupData = [];
      const newGroups = [];
      if (!data) return;
      data.forEach((unit) => {
        const val = unit[key];
        if (groupData.indexOf(val) === -1) {
          groupData.push(val);
          const units = data.filter((unit) => unit[key] === val);
          newGroups.push({ [key]: val ? val : `No ${key}`, units: sortUnits(units) });
        }
      }, []);

      const newGroups2 = reorderGroup2(newGroups, seq);
      updateGroups(reorderGroup3(newGroups2));
    }
    setPrevGroupBy(unitGroupBy);
  };
  // Drag'n drop
  const handleDragOver = (ev) => {
    ev.preventDefault();
    clearTimeout(draggingTimeRef.current);
    if (!dragging) {
      const draggedData = JSON.parse(localStorage.getItem('dragNdrop'));
      if (!draggedData) return;
      setDragging(true);
      if (areObjEqual(dragged, draggedData)) return;
      setDragged(draggedData);
    }
  };

  const handleDragLeave = (ev) => {
    clearTimeout(draggingTimeRef.current);
    draggingTimeRef.current = setTimeout(() => {
      setDragging(false);
    }, 50);
  };

  const handleDrop = (ev) => {
    clearTimeout(draggingTimeRef.current);
    setDragging(false);
    if (dragged?.ptsEventID) {
      dragged.units.forEach((ptsUnitID) => {
        const status = getStatusName('Inservice') || getStatusName('InService');
        props.setUnitStatus(status, ptsUnitID, dragged.ptsEventID);
      });
    }
    localStorage.removeItem('dragNdrop');
  };

  const getStatusName = (statusCode) => {
    return options.UnitActionCodes[statusCode].status;
  };

  const getFilteredUnits = (units) => {
    if (showPinned) units = units.filter((unit) => pinned.indexOf(unit.ptsUnitID) !== -1);
    if (unitAgencyFilter.length)
      units = units.filter((u) => unitAgencyFilter.indexOf(u.AgencyID) === -1);
    if (filter) units = filterArr(filter, units, filterCols);
    return units;
  };

  const renderUnits = () => {
    let units = getFilteredUnits(groups[0].units);
    return <Card>{groups.length > 0 && unitList(units, 'none')}</Card>;
  };

  const findCurGroupId = () => {
    let key;
    switch (unitGroupBy) {
      case 'agency':
        key = 'AgencyID';
        break;
      case 'status':
        key = 'UnitStatus';
        break;
      case 'zone':
        key = 'Zone';
        break;
      case 'Division':
        key = 'Division';
        break;
    }
    return key;
  };

  const saveGroupsInLs = (curGroups) => {
    const id = findCurGroupId();
    const sequence = curGroups.map((group) => group[id]);
    const saveObj = { ...unitGroups, [unitGroupBy]: sequence };
    props.updateUnitGroups(saveObj);
  };

  const renderGroups = () => {
    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
      const items = [...groups];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      updateGroups(items);
      saveGroupsInLs(items);
    };

    return (
      <>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="groups">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {groups &&
                  groups
                    .map((group) => {
                      const units = getFilteredUnits(group.units);
                      const groupKey = getGroupByKey(prevGroupBy);
                      const Code = group[groupKey];
                      const key = Code || '';
                      if (groupKey === 'UnitStatus' && key === statusStandby) return null;
                      if (unitAgencyFilter.length && unitAgencyFilter.includes(group.AgencyID))
                        return null;
                      return { ...group, key, Code, groupKey, units };
                    })
                    .filter((group) => group)
                    .map((group, idx) => {
                      const { key, Code, units } = group;
                      return (
                        <Draggable key={key} draggableId={key} index={idx}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <UnitsGroup
                                key={key}
                                groupKey={key}
                                group={group}
                                unfoldSubject={unfoldSubject}
                                groupBy={prevGroupBy}
                                header={groupHeader(group, Code)}
                                unitList={() => unitList(units, key)}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const groupHeader = (group, key) => () => {
    const { units } = group;

    const offDutyUnitsCount = rowCounts?.filter(
      (row) => row.AgencyID === group.AgencyID && row.UnitStatus === statusOutservice
    );
    const standByUnitsCount = rowCounts?.filter(
      (row) => row.AgencyID === group.AgencyID && row.UnitStatus === statusStandby
    );

    const inserviceUnitsCount = units.filter((u) => u.ptsUnitID && u.UnitStatus !== statusStandby)
      .length;
    return (
      <div className={classes.summaryContent}>
        <div className={classes.sumContentLeft}>
          {prevGroupBy === 'agency' && (
            <div className={classes.agencyIcon}>
              <AgencyIcon AgencyID={key} />
            </div>
          )}
          {key}
        </div>
        <div className={classes.sumContentRight}>
          {unitGroupBy === 'agency' && (
            <>
              {' '}
              <Tooltip title="Stand By">
                <div
                  className={classes.standByBadge}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.addMultiSelectableUnitDialog({
                      tabLabel: 'On Stand By',
                      tabVal: 'standby',
                      agencyId: group.AgencyID,
                    });
                  }}>
                  {standByUnitsCount.length && standByUnitsCount[0].length}
                </div>
              </Tooltip>
              <Tooltip title="Out of Service">
                <div
                  className={classes.offDutyBadge}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.addMultiSelectableUnitDialog({
                      tabLabel: 'Out of Service',
                      tabVal: 'outservice',
                      agencyId: group.AgencyID,
                    });
                  }}>
                  {offDutyUnitsCount?.length && offDutyUnitsCount[0].length}
                </div>
              </Tooltip>
            </>
          )}
          <Tooltip title="In Service">
            <div
              className={classes.inServiceBadge}
              onClick={(e) => {
                e.stopPropagation();
                props.addMultiSelectableUnitDialog({
                  tabLabel: 'In Service',
                  tabVal: 'inservice',
                  agencyId: group.AgencyID,
                });
              }}>
              {inserviceUnitsCount}
            </div>
          </Tooltip>
          {/* <div className={classes.badge}>{units.length}</div> */}
        </div>
      </div>
    );
  };

  dataPass.current.selection = selection;
  //dataPass.current.setSelection = setSelection;
  dataPass.current.togglePin = togglePin;
  dataPass.current.onUnitClick = onUnitClick;
  dataPass.current.colorPalette = colorPalette;

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={dragging ? classes.draggingOver : classes.wrap}>
      {prevGroupBy === 'none' && renderUnits()}
      {prevGroupBy !== 'none' && renderGroups()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    options: state.config.options,
    unitSort: state.unitSort,
    user: state.user.userData.user,
    unitGroups: state.unitGroups,
    unitAgencyFilter: state.userSettings.unitAgencyFilter,
    unitGroupBy: state.userSettings.unitGroupBy,
    unitSortBy: state.userSettings.unitSortBy,
    unitSortOrder: state.userSettings.unitSortOrder,
    unitSeq: state.userSettings.unitSeq,
    UnitActions: state.dictionary.UnitActions,
  };
};

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  updateUnitsResources,
  updateUnitGroups,
  addMultiSelectableUnitDialog,
  saveUserSetting,
})(UnitList);
