import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { showEditZone, showReport } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { getTotalNumberOfZones, searchZones } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import EditIcon from '@material-ui/icons/Edit';

import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ZoneCode', headerName: 'Zone Code', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'Zone1', headerName: 'Zone 1', width: 150, hide: false },
  { field: 'Zone2', headerName: 'Zone 2', width: 150, hide: false },
  { field: 'Zone3', headerName: 'Zone 3', width: 150, hide: false },
  { field: 'Zone4', headerName: 'Zone 4', width: 150, hide: false },
  { field: 'Zone5', headerName: 'Zone 5', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Zone(props) {
  const classes = useStyles();
  const { filter, agencyList } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [zoneColumns, setZoneColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    getAllZones();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  useEffect(() => {
    (async () => {
      const totalRows = await getTotalNumberOfZones(filter);
      totalRows.length && setRowCount(totalRows[0].count);
    })();
  }, [filter]);

  const getAllZones = async (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchZones(filter, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  // const tabFilter = (zones) => {
  //   if (!filter) return zones;
  //   return zones.filter((zone) => zone.AgencyID === filter);
  // };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 39, data: { ZoneCode: item.ZoneCode }, title: 'Zone Details' });
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };
  const handleEditZone = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    const { IntCode, ZoneCode } = item;
    console.log('selection ', item);
    props.showEditZone({ IntCode, ZoneCode });
    const onPageChange = () => {
      const offset = (page + 1) * limit;
      const mergingRows = true;
      getAllZones(offset, mergingRows);
      setPage(page + 1);
    };

    return (
      <div>
        <h5>Zones History</h5>
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <QuickFilterSearch
              rows={rows}
              columns={zoneColumns}
              setFilteredRows={setFilteredRows}
            />
          </div>
          <div className={classes.actions}>
            <Tooltip title="Print list">
              <Fab size="small" color="secondary" onClick={handlePrintClick}>
                <LocalPrintshopRoundedIcon />
              </Fab>
            </Tooltip>
            {selection !== null && (
              <>
                <Tooltip title="Edit Zone">
                  <Fab className="ml-2" size="small" color="secondary" onClick={handleEditZone}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="View details">
                  <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                    <InfoIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        {showAlert && (
          <RecordLimitAlert
            handleNextPage={onPageChange}
            rowCount={rowCount}
            currentRows={rows.length}
          />
        )}
        <XGrid3
          name="Zones History"
          columns={zoneColumns}
          setColumns={setZoneColumns}
          rows={filteredRows}
          loading={!loaded}
          setSelection={setSelection}
          printFlag={printFlag}
          viewDetails={viewDetails}
          refresh={getAllZones}
        />
      </div>
    );
  };
  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    getAllZones(offset, mergingRows);
    setPage(page + 1);
  };
  return (
    <div>
      <h5>Zones History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={zoneColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="Edit Zone">
                <Fab className="ml-2" size="small" color="secondary" onClick={handleEditZone}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Zones History"
        columns={zoneColumns}
        setColumns={setZoneColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={getAllZones}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showEditZone,
})(Zone);
