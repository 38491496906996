import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'Routes';
import store from 'config/configureStore';
import { history } from 'config/configureStore';
import ScrollToTop from 'utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from 'theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import NotificationPanel from 'components/NotificationPanel';
import Dialogs from 'Dialogs';
import { useLoadScript } from '@react-google-maps/api';
import { notify } from 'reducers/NotifierReducer';
import 'assets/base.scss';
import 'icons';
import {
  subscribeEvents,
  unsubscribeEvents,
  subscribeEventsfromStorage,
  unsubscribeEventsFromStorage,
} from 'reducers/EventsReducer';
import {
  subscribeUnits,
  unsubscribeUnits,
  subscribeUnitsfromStorage,
  unsubscribeUnitsfromStorage,
} from 'reducers/UnitsReducer';
import { subscribeE911, unsubscribeE911 } from 'reducers/E911Reducer';
import { subscribeBulletins, unsubscribeBulletins } from 'reducers/BulletinReducer';
import { subscribeDictionary, unsubscribeDictionary } from 'reducers/DictionaryReducer';
import { getSettings, checkStorageData } from 'reducers/ConfigReducer';
import { getDictionaries } from 'reducers/DictionaryReducer';
import { getUnitGroups } from 'reducers/UnitGroupsReducer';
import { updateZones } from 'reducers/ZonesReducer';
import { updateESNs } from 'reducers/EsnReducer';
import { LicenseInfo } from '@material-ui/x-grid';
import { findUnreadUserBulletins } from 'reducers/BulletinReducer';
import { checkPassExpiration } from 'reducers/UserReducer';
import { handleError } from 'reducers/ErrorReducer';
import {
  subscribeConnectionStatus,
  unsubscribeConnectionStatus,
} from 'reducers/ServerConnectionReducer';
import IdleTimerContainer from 'components/IdleTimerContainer';
import {
  getUserPermissions,
  subscribePermissions,
  unsubscribePermissions,
} from 'reducers/PermissionsReducer';
import { getServerTimeSettings, timeSyncInit } from './reducers/TimeReducer';
import { subscribeKeyboard, unsubscribeKeyboard } from './reducers/KeyboardReducer';
import GenericKeyboardHandler from 'components/GenericKeyboardHandler';
import { getApiCadVer, checkForUpdates, getApiVer } from 'reducers/AppInfoReducer';
import * as Sentry from '@sentry/react';
import { loadUserSettings } from 'reducers/UserSettingsReducer';

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');
const multipleScreens = process.env.REACT_APP_MULTIPLE_SCREENS === 'true';

const App = (props) => {
  const snackbarRef = useRef();
  const [mapError, setMapError] = useState(false);
  const [AppInfoRegistered, setAppInfoRegistered] = useState(false);
  const {
    authenticated,
    websocket,
    subscribeEvents,
    subscribeUnits,
    subscribeE911,
    unsubscribeE911,
    unsubscribeEvents,
    unsubscribeUnits,
    subscribeEventsfromStorage,
    unsubscribeEventsFromStorage,
    subscribeUnitsfromStorage,
    unsubscribeUnitsfromStorage,
    checkStorageData,
    subscribeBulletins,
    unsubscribeBulletins,
    subscribeDictionary,
    unsubscribeDictionary,
    subscribePermissions,
    unsubscribePermissions,
    findUnreadUserBulletins,
    ui,
    getSettings,
    updateZones,
    updateESNs,
    timeSyncInit,
    getDictionaries,
    checkPassExpiration,
    getUserPermissions,
    getServerTimeSettings,
    subscribeKeyboard,
    unsubscribeKeyboard,
    appInfo,
    getUnitGroups,
    loadUserSettings,
    subscribeConnectionStatus,
    unsubscribeConnectionStatus,
  } = props;

  const initLoaded = props.isSettingsLoaded && props.isPermissionsLoaded;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: mapLibraries,
  });
  if (loadError && !mapError) setMapError(true);
  const { showSpinner } = ui;
  const themeMode = () => (props.mode === 'night' ? NightTheme : DayTheme);

  useEffect(() => {
    if (!initLoaded) return;
    subscribeUnits();
    subscribeE911();
    getUnitGroups();
    getDictionaries();
    updateZones();
    updateESNs();
    subscribeBulletins();
    timeSyncInit();
    findUnreadUserBulletins();
    checkPassExpiration();
    subscribeDictionary();
    getServerTimeSettings();
    subscribePermissions();
    subscribeKeyboard();
    props.getApiCadVer();
    props.checkForUpdates();
    props.getApiVer();
    subscribeEvents();
    loadUserSettings();
    subscribeConnectionStatus();
    if (multipleScreens) {
      subscribeEventsfromStorage();
      subscribeUnitsfromStorage();
      checkStorageData();
    }
  }, [initLoaded]);

  useEffect(() => {
    if (!mapError) return;
    props.notify('Map loading error', 'error');
    // eslint-disable-next-line
  }, [mapError]);

  useEffect(() => {
    setSentryData();
    // eslint-disable-next-line
  }, [appInfo]);

  useEffect(() => {
    if (websocket.websocket && authenticated) {
      getSettings();
      getUserPermissions();
    }
    return () => {
      if (websocket.websocket && authenticated) {
        unsubscribeEvents();
        unsubscribeUnits();
        unsubscribeE911();
        unsubscribeBulletins();
        unsubscribeDictionary();
        unsubscribePermissions();
        unsubscribeKeyboard();
        unsubscribeConnectionStatus();
        if (multipleScreens) {
          unsubscribeEventsFromStorage();
          unsubscribeUnitsfromStorage();
        }
      }
    };
    // eslint-disable-next-line
  }, [websocket, authenticated]);

  const closeSnackbar = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  const setSentryData = () => {
    const {
      apiCadVersion,
      apiVersion,
      appVersion,
      availBuildNo,
      availBuildTime,
      buildNo,
      buildTime,
      lastCommit,
    } = appInfo;
    if (
      AppInfoRegistered ||
      apiCadVersion === '?' ||
      apiVersion === '?' ||
      appVersion === '?' ||
      availBuildNo === '?'
    )
      return;
    setAppInfoRegistered(true);
    const sentryAppInfo = {
      'CAD Version': appVersion,
      'Build No': buildNo,
      'Build Time': buildTime,
      'Avail. Build No': availBuildNo,
      'Avail. Build Time': availBuildTime,
      'API Version': apiVersion,
      'API CAD Module ver.': apiCadVersion,
      'Last Commit No': lastCommit,
    };
    delete sentryAppInfo.registration;
    Sentry.setContext('App version', sentryAppInfo);
    const username = props.user?.userData?.user?.Username || 'unknown';
    Sentry.setUser({ username });
  };

  if (!isLoaded) return 'Loading...';

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
              <SnackbarProvider
                ref={snackbarRef}
                action={(key) => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <NotificationPanel />
                <Routes />
                <IdleTimerContainer />
                <GenericKeyboardHandler />
              </SnackbarProvider>
              <Dialogs />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  mode: state.userSettings.themeMode,
  ui: state.ui,
  websocket: state.websocket,
  authenticated: state.user.isAuthenticated,
  user: state.user,
  addPage: state.paging.addPage,
  appInfo: state.appInfo,
  isPermissionsLoaded: state.permissions.loaded,
  isSettingsLoaded: state.config.loaded,
});

export default connect(mapStateToProps, {
  getSettings,
  notify,
  updateZones,
  updateESNs,
  subscribeEvents,
  unsubscribeEvents,
  subscribeEventsfromStorage,
  unsubscribeEventsFromStorage,
  subscribeUnitsfromStorage,
  unsubscribeUnitsfromStorage,
  checkStorageData,
  subscribeUnits,
  unsubscribeUnits,
  subscribeE911,
  unsubscribeE911,
  subscribeBulletins,
  unsubscribeBulletins,
  findUnreadUserBulletins,
  timeSyncInit,
  getDictionaries,
  handleError,
  checkPassExpiration,
  subscribeDictionary,
  unsubscribeDictionary,
  subscribePermissions,
  unsubscribePermissions,
  getUserPermissions,
  getServerTimeSettings,
  subscribeKeyboard,
  unsubscribeKeyboard,
  getApiCadVer,
  checkForUpdates,
  getApiVer,
  getUnitGroups,
  loadUserSettings,
  subscribeConnectionStatus,
  unsubscribeConnectionStatus,
})(App);
