import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getFormStyle } from '../../utils/functions';

const useStyles = makeStyles(() => ({
  formControl: {
    ...getFormStyle(170, 170),
  },
}));

function ActionType(props) {
  const classes = useStyles();
  const { value, setValue } = props;

  return (
    <FormControl className={classes.formControl} size="small">
      <InputLabel>Action Type</InputLabel>
      <Select
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        label="Action Type"
        className={classes.select}>
        <MenuItem value="Agency">Involved Agency</MenuItem>
        <MenuItem value="EventStatus">Event Status</MenuItem>
        <MenuItem value="EventType">Event Type</MenuItem>
        <MenuItem value="RequestedActions">Requested Action</MenuItem>
        <MenuItem value="FireSeverity">Fire Severity Level</MenuItem>
        <MenuItem value="EventDescription">Event Description</MenuItem>
        <MenuItem value="CallMethods">Call Methdos</MenuItem>
        <MenuItem value="Locale">Locale</MenuItem>
        <MenuItem value="SurroundingArea">Surrounding Area</MenuItem>
        <MenuItem value="Area">Area</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ActionType;
