import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { showReport } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchUnits } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import UnitSearchHotKeys from './UnitSearchHotKeys';
import { showAddUnit, showEditUnit } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setUnitStatus, unitInitEvent } from 'reducers/UnitStatusReducer';
import { newUnitStatus } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import { displayDateTime } from 'reducers/TimeReducer';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const defaultColumns = [
  'Mobile',
  'Unit',
  'Names',
  'Resources',
  'UnitStatus',
  { field: 'Occurred', header: 'Occurred', width: 200 },
  'AgencyID',
  'Division',
  'Zone',
  'Location',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const updateCases = ['add-unit', 'edit-unit', 'unit-status'];

function UnitEntries(props) {
  const classes = useStyles();
  const { filter, dataUpdate, options } = props;
  const { UnitActionCodes } = options;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [unitColumns, setUnitColumns] = useState(defaultColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const permissions = getFullPermissions('cad', 'Units', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate && updateCases.indexOf(dataUpdate.type) !== -1) {
      search();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  // useEffect(() => {
  //   (async () => {
  //     const totalRows = await getTotalNumberOfSops();
  //     totalRows.length && setRowCount(totalRows[0].count);
  //   })();
  // }, []);

  const search = async (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchUnits(filter, offset, limit)
      .then((result) => {
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows) setRows((prevState) => [...prevState, ...processData(result)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const tabFilter = (units) => {
    if (!filter) return units;
    return units.filter((unit) => unit.AgencyID === filter);
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        Occurred: displayDateTime(row.Occurred),
        id: row.ptsUnitID,
      };
    });
  };

  const viewDetails = () => {
    if (!selection) return;
    props.showReport({ id: 28, data: { ptsUnitID: selection }, title: 'Unit Details' });
  };

  const addUnit = () => props.showAddUnit();

  const editUnit = () => {
    props.showEditUnit(selection);
  };

  const addAttachment = () => {
    props.showAddAttachment({ id: selection, type: 'Unit' });
  };

  const renderStatusMenu = () => {
    const viewStatus = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleUnitInitEvent = () => {
      props.unitInitEvent(selection);
      handleClose();
    };

    const handleAddNewStatus = () => {
      props.newUnitStatus(selection);
      handleClose();
    };

    const handleInService = async () => {
      await props.setUnitStatus(UnitActionCodes.Inservice.status, selection);
      search();
      handleClose();
    };

    const handleOffDuty = () => {
      props.setUnitStatus(UnitActionCodes.Outservice.status, selection);
      search();
      handleClose();
    };

    return (
      <div>
        <Tooltip title="Additional options">
          <Fab size="small" color="secondary" onClick={viewStatus}>
            <MoreVertIcon />
          </Fab>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem onClick={handleUnitInitEvent}>Unit Initiated Event</MenuItem>
          <MenuItem onClick={handleAddNewStatus}>Add New</MenuItem>
          <MenuItem onClick={handleInService}>In Service</MenuItem>
          <MenuItem onClick={handleOffDuty}>Off Duty</MenuItem>
        </Menu>
      </div>
    );
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  return (
    <div>
      <h5>Units</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={unitColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          {permissions.Create && (
            <Tooltip title="Create new unit">
              <Fab onClick={addUnit} size="small" color="secondary">
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
          {selection !== null && permissions.Edit && (
            <Tooltip title="Edit unit">
              <Fab onClick={editUnit} size="small" color="secondary">
                <EditIcon />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="View details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
              {renderStatusMenu()}
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Units"
        columns={unitColumns}
        setColumns={setUnitColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
      {Boolean(selection) && <UnitSearchHotKeys ptsUnitID={selection} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
    options: state.config.options,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddUnit,
  showEditUnit,
  showAddAttachment,
  setUnitStatus,
  newUnitStatus,
  unitInitEvent,
})(UnitEntries);
