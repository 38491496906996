import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { displayDateTime, displayDate } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {
  showReport,
  showAddVehicle,
  showEditVehicle,
  showAddAttachment,
} from 'reducers/DialogsReducer';
import { searchVehicles, removeVehicle, getTotalNumberOfVehicles } from 'reducers/SearchReducer';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import QuickSearch from '../components/QuickSearch';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import Checkbox2 from 'components/Checkbox2';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'VehicleID',
  'PlateNumber',
  'PlateState',
  {
    field: 'PlateExpiration',
    valueFormatter: (params) => displayDate(params.value),
  },
  'VIN',
  'Year',
  'Make',
  'Model',
  'Style',
  'PrimaryColor',
  {
    field: 'IsWanted',
    renderCell: bool,
    format: 'bool',
  },
  'Party',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const noDeletedColumns = [
  'VehicleID',
  'PlateNumber',
  'PlateState',
  {
    field: 'PlateExpiration',
    valueFormatter: (params) => displayDate(params.value),
  },
  'VIN',
  'Year',
  'Make',
  'Model',
  'Style',
  'PrimaryColor',
  {
    field: 'IsWanted',
    renderCell: bool,
    format: 'bool',
  },
  'Party',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

const searchCols = [
  'PlateNumber',
  'PlateState',
  'VIN',
  'Make',
  'Model',
  'Style',
  'PrimaryColor',
  'Year',
];
let lastSearchTerm = '';

function Vehicle(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const permissions = getFullPermissions('globals', 'Vehicles', 'any');
  const userColumns = permissions.ViewDeleted ? defaultColumns : noDeletedColumns;
  const [rawRows, setRawRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [vehicleColumns, setVehicleColumns] = useState(userColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [ViewDeleted, setViewDeleted] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (
      dataUpdate &&
      (dataUpdate.type === 'add-vehicle' ||
        dataUpdate.type === 'vehicle-reg' ||
        dataUpdate.type === 'edit-vehicle')
    )
      search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [ViewDeleted]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const getTotalRows = async (text) => {
    const totalRows = await getTotalNumberOfVehicles(text, ViewDeleted);
    totalRows.length && setRowCount(totalRows[0].count);
  };

  const search = async (text = lastSearchTerm, offset = 0, mergeRows = false) => {
    lastSearchTerm = text;
    if (!text || text.length < 3) return;
    setLoaded(false);
    searchVehicles(text, ViewDeleted, offset, limit)
      .then(async (result) => {
        await getTotalRows(text);
        const nextStartingIndex = rows.length;
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const addVehicle = () => {
    props.showAddVehicle();
  };

  const editVehicle = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showEditVehicle(item);
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 33,
      data: { ptsVehicleID: item.ptsVehicleID },
      title: 'Vehicle Details',
    });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsVehicleID } = item;
    props.showAddAttachment({ id: ptsVehicleID, type: 'Vehicle' });
  };

  const delVehicle = () => {
    if (!window.confirm('Are you sure you want to remove selected vehicle?')) return;
    const item = rows.find((row) => row.id === selection);
    const { ptsVehicleID } = item;
    props.showSpinner();
    removeVehicle(ptsVehicleID)
      .then(() => search())
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(lastSearchTerm, offset, mergingRows);
    setPage(page + 1);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Vehicles History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickSearch
            search={search}
            rows={rawRows}
            setRows={setRows}
            cols={searchCols}
            loaded={loaded}
          />
          {permissions.ViewDeleted && (
            <Checkbox2
              label="Show Deleted"
              checked={ViewDeleted}
              onChange={(ev, val) => setViewDeleted(val)}
            />
          )}
        </div>
        <div className={classes.actions}>
          {permissions.Create && (
            <Tooltip title="Add vehicle">
              <Fab onClick={addVehicle} size="small" color="secondary">
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              {permissions.Edit && (
                <Tooltip title="Edit Vehicle">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editVehicle}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
              {permissions.Delete && (
                <Tooltip title="Remove vehicle">
                  <Fab className="ml-2" color="secondary" size="small" onClick={delVehicle}>
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Vehicles History"
        columns={vehicleColumns}
        setColumns={setVehicleColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddVehicle,
  showEditVehicle,
  showAddAttachment,
  showSpinner,
  hideSpinner,
})(Vehicle);
