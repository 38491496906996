import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { displayDate } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport, showAddPerson, showEditPerson } from 'reducers/DialogsReducer';
import { getTotalNumberOfPeople, searchPeople2 } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import XGrid3 from 'components/XGrid3';
import { getPersonWarrantCount } from 'reducers/WarrantReducer';
import { notify } from 'reducers/NotifierReducer';
import Badge from '@material-ui/core/Badge';
import { showWarrants } from 'reducers/DialogsReducer';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import Checkbox2 from 'components/Checkbox2';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 8,
    },
  },
  warrantBtn: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.error.dark,
    },
    '& > span > span > span': {
      background: theme.card.bg,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    '&.MuiDataGrid-row:hover': {
      background: theme.palette.error.light,
    },
    '&.MuiDataGrid-row.Mui-selected': {
      background: theme.palette.error.dark,
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      background: theme.palette.error.dark,
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'PersonID',
  'FullName',
  'FirstName',
  'MiddleName',
  'LastName',
  'Suffix',
  'NickName',
  'Age',
  'Sex',
  'OLN',
  'SSN',
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  'BirthDate',
  { field: 'Deceased', renderCell: bool },
  { field: 'Died', valueFormatter: (params) => displayDate(params.value) },
  { field: 'ActiveWarrant', renderCell: bool },
  { field: 'Incarcerated', renderCell: bool },
  { field: 'Officer', renderCell: bool },
  { field: 'PublicOfficial', renderCell: bool },
  { field: 'ProtectiveOrder', renderCell: bool },
  { field: 'SexOffender', renderCell: bool },
  { field: 'Military', renderCell: bool },
  { field: 'ConcealedWeaponPermit', headerName: 'Concealed Weapon', renderCell: bool },
  { field: 'SeniorCitizen', renderCell: bool },
  { field: 'Dangerous', renderCell: bool },
  { field: 'Gang', renderCell: bool },
  'Ethnicity',
  'EyeColor',
  'HairColor',
  'Race',
  'Height',
  'Weight',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const noDeletedColumns = [
  'PersonID',
  'FullName',
  'FirstName',
  'MiddleName',
  'LastName',
  'Suffix',
  'NickName',
  'Age',
  'Sex',
  'OLN',
  'SSN',
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  'BirthDate',
  { field: 'Deceased', renderCell: bool },
  { field: 'Died', valueFormatter: (params) => displayDate(params.value) },
  { field: 'ActiveWarrant', renderCell: bool },
  { field: 'Incarcerated', renderCell: bool },
  { field: 'Officer', renderCell: bool },
  { field: 'PublicOfficial', renderCell: bool },
  { field: 'ProtectiveOrder', renderCell: bool },
  { field: 'SexOffender', renderCell: bool },
  { field: 'Military', renderCell: bool },
  { field: 'ConcealedWeaponPermit', headerName: 'Concealed Weapon', renderCell: bool },
  { field: 'SeniorCitizen', renderCell: bool },
  { field: 'Dangerous', renderCell: bool },
  { field: 'Gang', renderCell: bool },
  'Ethnicity',
  'EyeColor',
  'HairColor',
  'Race',
  'Height',
  'Weight',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

function Person(props) {
  const classes = useStyles();
  const permissions = getFullPermissions('globals', 'People', 'any');
  const userColumns = permissions.ViewDeleted ? defaultColumns : noDeletedColumns;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [personColumns, setPersonColumns] = useState(userColumns);
  const [hasWarrant, setHasWarrant] = useState(false);
  const [warrantsNo, setWarrantsNo] = useState([]);
  const [printFlag, setPrintFlag] = useState(false);
  const [ViewDeleted, setViewDeleted] = useState(false);
  const dataUpdate = useSelector((state) => state.dataUpdate);
  const [text, setText] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (dataUpdate && dataUpdate.type == 'Person') {
      search();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [ViewDeleted]);

  useEffect(() => {
    if (selection === null) return;
    const warrant = () => {
      const item = rows.find((row) => row.id === selection);
      return item.ActiveWarrant;
    };
    if (warrant !== hasWarrant) {
      setHasWarrant(warrant);
      getWarrants();
    }
    // eslint-disable-next-line
  }, [selection]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (offset = 0, mergeRows = false) => {
    if (text.length < 3) return;
    setLoaded(false);
    searchPeople2(text, ViewDeleted, offset, limit)
      .then(async (result) => {
        result.length && setRowCount(result[0].Count);
        const nextStartingIndex = rows.length;

        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => ({
      ...row,
      id: idx + nextStartingIndex,
      BirthDate: displayDate(row.BirthDate),
    }));
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 45, data: { ptsPersonID: item.ptsPersonID }, title: 'Person Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editPerson = () => {
    const item = rows.find((row) => row.id === selection);
    props.showEditPerson(item);
  };

  const getWarrants = async () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    try {
      const warrants = await getPersonWarrantCount(ptsPersonID);
      setWarrantsNo(warrants);
    } catch (err) {
      props.handleError(err);
    }
  };

  const viewWarrant = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPersonID, FirstName, LastName } = item;
    const Name = `${FirstName} ${LastName}`;
    props.showWarrants({ Name, ptsPersonID });
  };

  const handleAdd = () => props.showAddPerson();
  const getRowClassName = (data) => (data.row.ActiveWarrant ? classes.warrant : null);

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };
  const onTextChange = (ev) => {
    const val = ev.target.value;
    setText(val);
  };

  const onKeyDown = (ev) => {
    if (ev.code === 'Enter') search();
  };

  const renderSearchBtn = () => {
    const disabled = text.length < 3;
    return (
      <div className={classes.btn}>
        <Button onClick={() => search()} variant="outlined" disabled={disabled}>
          Search
        </Button>
      </div>
    );
  };

  return (
    <div>
      <h5>People History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <TextField
            label="Search"
            type="search"
            className={classes.search}
            variant="outlined"
            size="small"
            onChange={onTextChange}
            value={text}
            onKeyDown={onKeyDown}
          />
          {renderSearchBtn()}
          {permissions.ViewDeleted && (
            <Checkbox2
              label="Show Deleted"
              checked={ViewDeleted}
              onChange={(ev, val) => setViewDeleted(val)}
              className={classes.checkbox}
            />
          )}
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Add person">
            <Fab size="small" color="secondary" onClick={handleAdd}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {warrantsNo > 0 && (
            <Tooltip title="View warrant info">
              <Fab size="small" onClick={viewWarrant} className={classes.warrantBtn}>
                <Badge badgeContent={warrantsNo}>ACT</Badge>
              </Fab>
            </Tooltip>
          )}
          {selection !== null && (
            <>
              <Tooltip title="View details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Edit person">
                <Fab size="small" color="secondary" onClick={editPerson}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="People History"
        columns={personColumns}
        setColumns={setPersonColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        getRowClassName={getRowClassName}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showAddAttachment,
  showAddPerson,
  showEditPerson,
  showDisplayDataWindow,
  notify,
  showWarrants,
})(Person);
