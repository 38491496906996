import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from 'components/Tooltip';
import Caller from './Caller';
import Person from './Person';
import Place from './Place';
import { sortObjArr } from 'utils/functions';
import { callerEmptyData } from './emptyData';
import { saveParty, delPartyCaller, delPartyPerson, delPartyPlace } from 'reducers/EventsReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import settings from 'config/settings';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { findBulletins } from 'reducers/BulletinReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showWarrants, showEditPerson } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  parties: {
    textAlign: 'left',
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    '& td': {
      borderBottom: `1px solid ${theme.table.borderColor}`,
      paddingLeft: theme.spacing(1),
      paddingTop: 4,
      paddingBottom: 4,
      '&:first-child': {
        paddingLeft: theme.spacing(2),
      },
      '&:last-child': {
        paddingRight: theme.spacing(2),
        position: 'relative',
      },
      '& i': {
        marginRight: '1em',
        fontStyle: 'normal',
      },
    },
    '& strong': {
      fontWeight: 500,
    },
    '& tr': {
      '&:hover': {
        '& $rowActions': {
          opacity: 1,
        },
      },
    },
  },
  th: {
    backgroundColor: theme.table.nthRowBg,
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.colors.errorContrast,
    '& svg': {
      color: theme.colors.errorContrast,
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  edit: {
    padding: theme.spacing(2),
  },
  rowActions: {
    position: 'absolute',
    right: 8,
    top: 0,
    border: 'none',
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    whiteSpace: 'nowrap',
    '& label': {
      marginBottom: 0,
    },
  },
  act: {
    display: 'inline-block',
    color: theme.card.bg,
    fontWeight: 600,
  },
}));

function Parties(props) {
  const classes = useStyles();
  const { sortOption, data, ptsEventID, Relationships, disableEdit } = props;
  const [callers, setCallers] = useState([]);
  const [persons, setPersons] = useState([]);
  const [places, setPlaces] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [valid, setValid] = useState(false);
  const perms = getFullPermissions('cad', 'Events', 'any');
  const edited =
    editedData && (editedData.ptsCallerID || editedData.ptsPersonID || editedData.ptsPlaceID);

  useEffect(() => {
    if (perms.Create && !disableEdit) {
      setEditedData(callerEmptyData);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Sorting data
    if (!data) return;
    listData();
    // eslint-disable-next-line
  }, [data, sortOption, edited]);

  useEffect(() => {
    if (!editedData) return valid && setValid(false);
    let isValid;
    switch (editedData.party) {
      case 'caller':
        isValid = validateCaller();
        break;
      case 'person':
        isValid = validatePerson();
        break;
      case 'place':
        isValid = validatePlace();
        break;
      default:
    }
    setValid(isValid);
    // eslint-disable-next-line
  }, [editedData]);

  const listData = () => {
    if (!edited) {
      setCallers(sortObjArr(data.callers, 'LastName', sortOption));
      setPersons(sortObjArr(data.persons, 'LastName', sortOption));
      setPlaces(sortObjArr(data.places, 'LastName', sortOption));
      return;
    }
    let callers = [],
      persons = [],
      places = [];
    const { ptsCallerID, ptsPersonID, ptsPlaceID } = editedData;
    if (ptsCallerID) {
      const result = data.callers.find((c) => c.ptsCallerID === ptsCallerID);
      callers = result ? [result] : [];
    } else if (ptsPersonID) {
      const result = data.persons.find((p) => p.ptsPersonID === ptsPersonID);
      persons = result ? [result] : [];
    } else if (ptsPlaceID) {
      const result = data.places.find((p) => p.ptsPlaceID === ptsPlaceID);
      places = result ? [result] : [];
    }
    setCallers(callers);
    setPersons(persons);
    setPlaces(places);
  };

  const validateCaller = () => {
    const { Relationship, FirstName, LastName, AdvisoryText, CallerLocation } = editedData;
    if (!Relationship || !(FirstName || LastName || AdvisoryText || CallerLocation)) return false;
    return true;
  };

  const validatePerson = () => {
    const { Relationship, ptsPersonID } = editedData;
    if (!Relationship || !ptsPersonID) return false;
    return true;
  };

  const validatePlace = () => {
    const { Relationship, ptsPlaceID } = editedData;
    if (!Relationship || !ptsPlaceID) return false;
    return true;
  };

  const onAddParty = () => {
    setEditedData(callerEmptyData);
  };

  const editCaller = (caller) => {
    const data = {
      ...caller,
      party: 'caller',
      AdvisoryText: caller.AdvisoryText ? caller.AdvisoryText : '',
      CallerLocation: caller.CallerLocation ? caller.CallerLocation : '',
      FirstName: caller.FirstName ? caller.FirstName : '',
      LastName: caller.LastName ? caller.LastName : '',
      Info: caller.Info ? caller.Info : '',
    };
    const { Relationship } = caller;
    if (Relationship) {
      data.Relationship = {
        Code: Relationship,
        Description: Relationships.find((rel) => rel.Code === Relationship).Description,
      };
    }
    setEditedData(data);
  };

  const renderCallers = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Callers</strong>
          </td>
        </tr>
        {callers.map((caller) => (
          <tr key={caller.ptsCallerID} className={caller.ActiveWarrant ? classes.warrant : null}>
            <td>
              {caller.LastName}, {caller.FirstName}
            </td>
            <td>{caller.Relationship}</td>
            <td>
              {caller.AdvisoryText ? <i>{caller.AdvisoryText}</i> : <></>}
              {caller.CallerLocation ? (
                <i>
                  <strong>Location: </strong> {caller.CallerLocation}
                </i>
              ) : (
                <></>
              )}
              {caller.Info ? (
                <i>
                  <strong>Info: </strong>
                  {caller.Info}
                </i>
              ) : (
                <></>
              )}
              <div className={classes.rowActions}>
                {caller.ActiveWarrant && !edited && (
                  <Tooltip title="View warrant">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => showWarrant(caller)}>
                      <small className={classes.act}>ACT</small>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit person">
                  <IconButton
                    color="primary"
                    size="small"
                    component="span"
                    onClick={() => showPersonDetails(caller)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                {perms.Edit && !edited && (
                  <Tooltip title="Edit party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => editCaller(caller)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {perms.Delete && !edited && (
                  <Tooltip title="Delete party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => delCaller(caller)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const editPerson = (person) => {
    const data = {
      ...person,
      party: 'person',
      FirstName: person.FirstName ? person.FirstName : '',
      LastName: person.LastName ? person.LastName : '',
      AdvisoryText: person.AdvisoryText ? person.AdvisoryText : '',
    };
    const { Relationship } = person;
    if (Relationship) {
      data.Relationship = {
        Code: Relationship,
        Description: Relationships.find((rel) => rel.Code === Relationship)?.Description || '',
      };
    }
    setEditedData(data);
  };

  const showWarrant = (data) => {
    const { ptsPersonID, FirstName, LastName } = data;
    const Name = `${FirstName} ${LastName}`;
    props.showWarrants({ Name, ptsPersonID });
  };

  const showPersonDetails = (person) => {
    props.showEditPerson(person);
  };

  const renderPersons = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Persons</strong>
          </td>
        </tr>
        {persons.map((person, idx) => (
          <tr
            key={'' + person.ptsPlaceID + idx}
            className={person.ActiveWarrant ? classes.warrant : null}>
            <td>
              {person.LastName}, {person.FirstName}
            </td>
            <td>{person.Relationship}</td>
            <td>
              {person.AdvisoryText}
              <div className={classes.rowActions}>
                {person.ActiveWarrant && !edited && (
                  <Tooltip title="View warrant">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => showWarrant(person)}>
                      <small className={classes.act}>ACT</small>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit Person">
                  <IconButton
                    color="primary"
                    size="small"
                    component="span"
                    onClick={() => showPersonDetails(person)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                {perms.Edit && !edited && (
                  <Tooltip title="Edit party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => editPerson(person)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {perms.Delete && !edited && (
                  <Tooltip title="Delete party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => delPerson(person)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const editPlace = (place) => {
    const data = {
      ...place,
      party: 'place',
      PlaceName: place.PlaceName ? place.PlaceName : '',
      AdvisoryText: place.AdvisoryText ? place.AdvisoryText : '',
    };
    const { Relationship } = place;
    if (Relationship) {
      data.Relationship = {
        Code: Relationship,
        Description: Relationships.find((rel) => rel.Code === Relationship).Description,
      };
    }
    setEditedData(data);
  };

  const renderPlaces = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Places</strong>
          </td>
        </tr>
        {places.map((place, idx) => (
          <tr key={'' + place.ptsPlaceID + idx}>
            <td>{place.PlaceName}</td>
            <td>{place.Relationship}</td>
            <td>
              {place.AdvisoryText}
              <div className={classes.rowActions}>
                {perms.Edit && !edited && (
                  <Tooltip title="Edit party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => editPlace(place)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {perms.Delete && !edited && (
                  <Tooltip title="Delete party">
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      onClick={() => delPlace(place)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const renderAddParty = () => {
    const { party } = editedData;
    return (
      <>
        {party === 'caller' && (
          <Caller
            updateData={updateEditedData}
            data={editedData}
            ptsEventID={ptsEventID}
            Relationships={Relationships}
            options={props.options}
          />
        )}
        {party === 'person' && (
          <Person
            updateData={updateEditedData}
            data={editedData}
            Relationships={Relationships}
            persons={persons}
          />
        )}
        {party === 'place' && (
          <Place
            updateData={updateEditedData}
            data={editedData}
            Relationships={Relationships}
            places={places}
          />
        )}
      </>
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!editedData ? (
          <>
            {perms.Create && (
              <Tooltip title="Add Party">
                <IconButton color="primary" size="small" component="span" onClick={onAddParty}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={onSave}
              disabled={!valid}>
              <SaveIcon />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={() => setEditedData(null)}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </div>
    );
  };

  const updateEditedData = (data) => {
    setEditedData(data);
  };

  const delCaller = (caller) => {
    if (!window.confirm('Are you sure you want to remove this party?')) return;
    delPartyCaller(caller.ROWGUID)
      .then(() => {
        // props.notify('Party deleted', 'info');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, party not deleted'));
  };

  const delPerson = (person) => {
    if (!window.confirm('Are you sure you want to remove this party?')) return;
    delPartyPerson(person.ROWGUID)
      .then(() => {
        //props.notify('Party deleted', 'info');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, party not deleted'));
  };

  const delPlace = (place) => {
    if (!window.confirm('Are you sure you want to remove this party?')) return;
    delPartyPlace(place.ROWGUID)
      .then(() => {
        //props.notify('Party deleted', 'info');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, place not deleted'));
  };

  const saveCaller = () => {
    const data = {
      ...editedData,
      ptsEventID,
      FirstName: editedData.FirstName ? editedData.FirstName : null,
      LastName: editedData.LastName ? editedData.LastName : null,
      Relationship: editedData.Relationship ? editedData.Relationship.Code : null,
      AdvisoryText: editedData.AdvisoryText ? editedData.AdvisoryText : null,
      CallerLocation: editedData.CallerLocation ? editedData.CallerLocation : null,
      Info: editedData.Info ? editedData.Info : null,
      ptsPersonID: editedData.ptsPersonID ? editedData.ptsPersonID : null,
    };

    setEditedData(null);
    saveParty(data.ROWGUID, data)
      .then(() => {
        //props.notify('Party saved', 'success');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, party not saved'));
  };

  const savePerson = () => {
    const data = {
      ...editedData,
      ptsEventID,
      FirstName: editedData.FirstName ? editedData.FirstName : null,
      LastName: editedData.LastName ? editedData.LastName : null,
      Relationship: editedData.Relationship ? editedData.Relationship.Code : null,
      AdvisoryText: editedData.AdvisoryText ? editedData.AdvisoryText : null,
    };
    setEditedData(null);
    saveParty(data.ROWGUID, data)
      .then(() => props.findBulletins({ ptsPersonID: data.ptsPersonID }))
      .then(() => {
        //props.notify('Party saved', 'success');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, party not saved'));
  };

  const savePlace = () => {
    const data = {
      ...editedData,
      ptsEventID,
      PlaceName: editedData.PlaceName ? editedData.PlaceName : null,
      Relationship: editedData.Relationship ? editedData.Relationship.Code : null,
      AdvisoryText: editedData.AdvisoryText ? editedData.AdvisoryText : null,
    };
    setEditedData(null);
    saveParty(data.ROWGUID, data)
      .then(() => {
        //props.notify('Party saved', 'success');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, party not saved'));
  };

  const onSave = () => {
    switch (editedData.party) {
      case 'caller':
        saveCaller();
        break;
      case 'person':
        savePerson();
        break;
      case 'place':
        savePlace();
        break;
      default:
    }
  };

  return (
    <div className={classes.parties}>
      {!disableEdit && renderActions()}
      {!!editedData && renderAddParty()}
      <table className={classes.table}>
        <tbody>
          {!!callers.length && renderCallers()}
          {!!persons.length && renderPersons()}
          {!!places.length && renderPlaces()}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Relationships: state.dictionary.PartyRelationships,
    options: state.config.options,
    alertNotify: state.unitStatus.notify,
    notifications: state.unitStatus.notifications,
  };
};

export default connect(mapStateToProps, {
  notify,
  handleError,
  showDisplayDataWindow,
  findBulletins,
  notifyDataUpdate,
  showWarrants,
  showEditPerson,
})(Parties);
