import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const statusWidth = 100;

const useStyles = makeStyles(() => ({
  status: {
    width: statusWidth,
    flex: `0 0 ${statusWidth}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      width: '100%',
      textAlign: 'center',
    },
  },
}));

function EventStatus(props) {
  const classes = useStyles();
  const { colorPalette, event, onClick, menuRef } = props;
  const { Status } = event;
  const EventColors = colorPalette.Events;
  const statusColor = EventColors[Status];
  return (
    <div className={classes.status} ref={menuRef} onClick={onClick} >
      <span style={{ background: statusColor, color: '#000' }}>{Status}</span>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    colorPalette: state.config.colorPalette,
  }
}

export default connect(mapStateToProps)(EventStatus);
