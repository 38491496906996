import { SET_SETTINGS_VAL } from './ConfigReducer';
const ADD_PAGE = 'PAGING/ADD_PAGE';
const UNIT_STATUS = 'UNIT_STATUS';
const ADD_EVENT = 'ADD_EVENT';
const EDIT_EVENT = 'EDIT_EVENT';
const ADD_SOP = 'ADD_SOP';
const EDIT_SOP = 'EDIT_SOP';
const EDIT_PAGE_GROUP = 'EDIT_PAGE_GROUP';
const EDIT_PAGE_RECIPIENT = 'EDIT_PAGE_RECIPIENT';
const EDIT_PAGE_TEMPLATE = 'EDIT_PAGE_TEMPLATE';
const DISPATCH_RECOMMENDATIONS = 'DISPATCH_RECOMMENDATIONS';
const UNIT_PROPS = 'UNIT_PROPS';
const ITEM_DETAIL = 'ITEM_DETAIL';
const NEW_UNIT_STATUS = 'NEW_UNIT_STATUS';
const MULTIPLE_UNIT_STATUS_CHANGE = 'MULTIPLE_UNIT_STATUS_CHANGE';
const UNIT_ZONE = 'UNIT_ZONE';
const OPEN_SETTINGS = 'OPEN_SETTINGS';
const CLOSE_SETTINGS = 'CLOSE_SETTINGS';
const DISPLAY_DATA = 'DISPLAY_DATA';
const SOPS = 'SOPS';
const ADD_BULLETIN = 'ADD_BULLETIN';
const EDIT_BULLETIN = 'EDIT_BULLETIN';
const ADDRESS = 'ADDRESS';
const BULLETIN_DETAILS = 'BULLETIN_DETAILS';
const REPORT = 'REPORT';
const ROTATIONS = 'ROTATIONS';
const ADD_VEHICLE = 'ADD_VEHICLE';
const EDIT_VEHICLE = 'EDIT_VEHICLE';
const VEHICLE_EDIT_REG = 'VEHICLE_EDIT_REG';
const TOWING = 'TOWING';
const ABOUT = 'ABOUT';
const ADD_Unit = 'ADD_Unit';
const DICTIONARY = 'DICTIONARY';
const TESTING_DIALOG = 'TESTING_DIALOG';
const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
const EDIT_ATTACHMENT = 'EDIT_ATTACHMENT';
const EDIT_UNIT = 'EDIT_UNIT';
const EDIT_CREDENTIAL = 'EDIT_CREDENTIAL';
const EDIT_PLACE = 'EDIT_PLACE';
const EDIT_CONTACT_METHOD = 'EDIT_CONTACT_METHOD';
const EDIT_PEOPLE_CREDENTIALS = 'EDIT_PEOPLE_CREDENTIALS';
const EDIT_PEOPLE_EMPLOYMENT = 'EDIT_PEOPLE_EMPLOYMENT';
const EDIT_PEOPLE_ADDRESS = 'EDIT_PEOPLE_ADDRESS';
const ADD_ADDRESS_SOP = 'ADD_ADDRESS_SOP';
const ADD_PEOPLE_SOP = 'ADD_PEOPLE_SOP';
const ADD_PLACE_SOP = 'ADD_PLACE_SOP';
const EDIT_NAME = 'EDIT_NAME';
const SHOW_PLACE_EMPLOYMENT = 'SHOW_PLACE_EMPLOYMENT';
const SHOW_WARRANTS = 'SHOW_WARRANTS';
const ADD_PERSON = 'ADD_PERSON';
const EDIT_PERSON = 'EDIT_PERSON';
const ADD_DNA_SAMPLE = 'ADD_DNA_SAMPLE';
const ADD_PERSON_FEATURE = 'ADD_PERSON_FEATURE';
const ADD_PERSON_PHOTO_ATTACHMENT = 'ADD_PERSON_PHOTO_ATTACHMENT';
const EDIT_PERSON_PHOTO = 'EDIT_PERSON_PHOTO';
const PERSON_NAME_DIALOG = 'PERSON_NAME_DIALOG';
const PERSON_RELATED_RECORDS = 'PERSON_RELATED_RECORDS';
const PLACE_ADDRESS_EDIT = 'PLACE_ADDRESS_EDIT';
const UNIT_RESOURCE_ADD_EDIT = 'UNIT_RESOURCE_ADD_EDIT';
const EDIT_ZONE = 'EDIT_ZONE';
const ZONE_SOP = 'ZONE_SOP';
const ADD_MULTI_SELECTABLE_UNIT_DIALOG = 'ADD_MULTI_SELECTABLE_UNIT_DIALOG';
const ROTATION_HISTORY = 'ROTATION_HISTORY';

const defaultDialogs = {
  about: null,
  editPageGroup: null,
  editPageRecipient: null,
  editPageTemplate: null,
  addEvent: null,
  editEvent: null,
  addOutserviceUnit: null,
  sops: null,
  addSop: null,
  editSop: null,
  dispRecommendation: null,
  unitProps: null,
  itemDetail: null,
  newUnitStatus: null,
  multipleUnitStatusChange: null,
  unitZone: null,
  settings: null,
  displayData: null,
  addBulletin: null,
  editBulletin: null,
  address: null,
  bulletinDetails: null,
  report: null,
  rotations: null,
  addVehicle: null,
  editVehicle: null,
  vehicleEditReg: null,
  towing: null,
  addUnit: null,
  dictionary: null,
  testing: null,
  editUnit: null,
  addAttachment: null,
  editAttachment: null,
  editCredential: null,
  editPlace: null,
  editName: null,
  editContactMethod: null,
  editPeopleCredentials: null,
  editPeopleEmployment: null,
  editPeopleAddress: null,
  addAddressSOP: null,
  addPeopleSOP: null,
  addPlaceSOP: null,
  editPlaceEmployment: null,
  addPerson: null,
  editPerson: null,
  warrants: null,
  personDnaSample: null,
  personFeature: null,
  personPhotoAttachment: null,
  editPersonPhoto: null,
  personName: null,
  personRelatedRecords: null,
  editPlaceAddress: null,
  addEditUnitResource: null,
  editZone: null,
  zoneSop: null,
  //addStandByOutService: null,
  addMultiSelectableUnit: null,
  editRotation: null,
};

const preventClosing = (openWindows, forbidWindows) => {
  if (!Array.isArray(openWindows)) return false;
  return forbidWindows.reduce((r, v) => (openWindows.indexOf(v) !== -1 ? true : r), false);
};

export const SET_ADDOUTSERVICEUNIT = 'SET_ADDOUTSERVICEUNIT';

export const addOutserviceUnitDialog = (show = true) => (dispatch) =>
  dispatch({ type: SET_ADDOUTSERVICEUNIT, show });
export const closeOutserviceUnitDialog = (openWindows) => (dispatch) => {
  const block = preventClosing(openWindows, ['newUnitStatus']);
  if (block) return;
  dispatch({ type: SET_ADDOUTSERVICEUNIT, show: false });
};

export const addMultiSelectableUnitDialog = (data = null) => (dispatch) =>
  dispatch({ type: ADD_MULTI_SELECTABLE_UNIT_DIALOG, data });

export const closeMultiSelectableUnitDialog = (openWindows) => (dispatch) => {
  const block = preventClosing(openWindows, ['multipleUnitStatusChange']);
  if (block) return;
  dispatch({ type: ADD_MULTI_SELECTABLE_UNIT_DIALOG, data: null });
};

export const addPageDialog = (data) => (dispatch) =>
  dispatch({ type: ADD_PAGE, data: data ? data : {} });
export const closeAddPageDialog = () => (dispatch) => dispatch({ type: ADD_PAGE, data: null });

export const addEvent = (data = true) => (dispatch) => dispatch({ type: ADD_EVENT, data });
export const closeAddEvent = (openWindows) => (dispatch) => {
  const block = preventClosing(openWindows, [
    'dictionary',
    'sops',
    'address',
    'dispRecommendation',
  ]);
  if (block) return;
  dispatch({ type: ADD_EVENT, data: null });
  dispatch({ type: SET_SETTINGS_VAL, key: 'show911', value: 2 });
};

export const editEvent = (ptsEventID) => (dispatch) =>
  dispatch({ type: EDIT_EVENT, data: ptsEventID });
export const closeEditEvent = (openWindows) => (dispatch) => {
  const block = preventClosing(openWindows, ['dictionary']);
  if (block) return;
  dispatch({ type: EDIT_EVENT, data: null });
};

export const showSops = (data) => (dispatch) => dispatch({ type: SOPS, data });
export const hideSops = () => (dispatch) => dispatch({ type: SOPS, data: null });

export const addSop = (data = true) => (dispatch) => dispatch({ type: ADD_SOP, data });
export const closeAddSop = () => (dispatch) => dispatch({ type: ADD_SOP, data: null });

export const editSop = (data) => (dispatch) => dispatch({ type: EDIT_SOP, data });
export const closeEditSop = () => (dispatch) => dispatch({ type: EDIT_SOP, data: null });

export const addBulletin = (data = { type: 'common' }) => (dispatch) =>
  dispatch({ type: ADD_BULLETIN, data });
export const closeAddBulletin = () => (dispatch) => dispatch({ type: ADD_BULLETIN, data: null });

export const editBulletin = (data = true) => (dispatch) => dispatch({ type: EDIT_BULLETIN, data });
export const closeEditBulletin = () => (dispatch) => dispatch({ type: EDIT_BULLETIN, data: null });

export const showAddressDialog = (data = true) => (dispatch) => dispatch({ type: ADDRESS, data });
export const closeAddressDialog = (openWindows) => (dispatch) => {
  const block = preventClosing(openWindows, ['dictionary']);
  if (block) return;
  dispatch({ type: ADDRESS, data: null });
};

export const showBulletinDetails = (data = true) => (dispatch) =>
  dispatch({ type: BULLETIN_DETAILS, data });
export const closeBulletinDetails = () => (dispatch) =>
  dispatch({ type: BULLETIN_DETAILS, data: null });

export const editPageGroup = (data) => (dispatch) => dispatch({ type: EDIT_PAGE_GROUP, data });
export const closeEditPageGroup = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_GROUP, data: null });

export const editPageRecipient = (data) => (dispatch) =>
  dispatch({ type: EDIT_PAGE_RECIPIENT, data });
export const closeEditPageRecipient = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_RECIPIENT, data: null });

export const editPageTemplate = (ROWGUID) => (dispatch) =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: ROWGUID });
export const closeEditPageTemplate = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: null });

export const dispRecommendations = (ptsEventID) => (dispatch) =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID });
export const closeDispRecommendations = () => (dispatch) =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID: null });

export const showUnitProps = (ptsUnitID) => (dispatch) => dispatch({ type: UNIT_PROPS, ptsUnitID });
export const closeUnitProps = () => (dispatch) => dispatch({ type: UNIT_PROPS, ptsUnitID: null });

export const showDisplayDataWindow = (data) => (dispatch) => dispatch({ type: DISPLAY_DATA, data });
export const closeDisplayDataWindow = () => (dispatch) =>
  dispatch({ type: DISPLAY_DATA, data: null });

export const showItemDetails = (ptsItemID) => (dispatch) =>
  dispatch({ type: ITEM_DETAIL, ptsItemID });
export const closeItemDetails = () => (dispatch) =>
  dispatch({ type: ITEM_DETAIL, ptsItemID: null });

export const newUnitStatus = (ptsUnitID, ptsEventID = null) => (dispatch) =>
  dispatch({
    type: NEW_UNIT_STATUS,
    payload: { ptsUnitID, ptsEventID, title: 'New Unit Status', edit: false },
  });
export const closeNewUnitStatus = () => (dispatch) =>
  dispatch({ type: NEW_UNIT_STATUS, payload: null });

export const multipleUnitStatusChangeDialog = (units = [], ptsEventID = null) => (dispatch) =>
  dispatch({
    type: MULTIPLE_UNIT_STATUS_CHANGE,
    payload: { units, ptsEventID, title: 'New Unit Status', edit: false },
  });
export const closeMultipleUnitStatusChangeDialog = () => (dispatch) =>
  dispatch({ type: MULTIPLE_UNIT_STATUS_CHANGE, payload: null });

export const editUnitStatus = (ptsUnitID, ptsEventID = null) => (dispatch) =>
  dispatch({
    type: NEW_UNIT_STATUS,
    payload: { ptsUnitID, ptsEventID, title: 'Edit Unit Status', edit: true },
  });
export const closeEditUnitStatus = () => (dispatch) =>
  dispatch({ type: NEW_UNIT_STATUS, payload: null });

export const editUnitAction = (ptsActionID) => (dispatch) =>
  dispatch({
    type: NEW_UNIT_STATUS,
    payload: { ptsActionID, title: 'Edit Unit Action', edit: true },
  });

export const setUnitZone = (ptsUnitID) => (dispatch) => dispatch({ type: UNIT_ZONE, ptsUnitID });
export const closeSetUnitZone = () => (dispatch) => dispatch({ type: UNIT_ZONE, ptsUnitID: null });

export const openSettings = () => (dispatch) => dispatch({ type: OPEN_SETTINGS });
export const closeSettings = () => (dispatch) => dispatch({ type: CLOSE_SETTINGS });

export const showReport = (data = true) => (dispatch) => dispatch({ type: REPORT, data });
export const closeReport = () => (dispatch) => dispatch({ type: REPORT, data: null });

export const showRotations = (data = true) => (dispatch) => dispatch({ type: ROTATIONS, data });
export const closeRotations = () => (dispatch) => dispatch({ type: ROTATIONS, data: null });

export const showAddVehicle = (data = {}) => (dispatch) => dispatch({ type: ADD_VEHICLE, data });
export const closeAddVehicle = () => (dispatch) => dispatch({ type: ADD_VEHICLE, data: null });

export const showEditVehicle = (data = {}) => (dispatch) => dispatch({ type: EDIT_VEHICLE, data });
export const closeEditVehicle = () => (dispatch) => dispatch({ type: EDIT_VEHICLE, data: null });

export const showVehicleEditReg = (data = true) => (dispatch) =>
  dispatch({ type: VEHICLE_EDIT_REG, data });
export const closeVehicleEditReg = () => (dispatch) =>
  dispatch({ type: VEHICLE_EDIT_REG, data: null });

export const showTowingDialog = (data = {}) => (dispatch) => dispatch({ type: TOWING, data });
export const closeTowingDialog = () => (dispatch) => dispatch({ type: TOWING, data: null });

export const showAboutDialog = () => (dispatch) => dispatch({ type: ABOUT, data: true });
export const closeAbooutDialog = () => (dispatch) => dispatch({ type: ABOUT, data: null });

export const showAddUnit = (data = {}) => (dispatch) => dispatch({ type: ADD_Unit, data });
export const closeAddUnit = () => (dispatch) => {
  dispatch({ type: ADD_Unit, data: null });
};

export const showEditUnit = (data = {}) => (dispatch) => dispatch({ type: EDIT_UNIT, data });
export const closeEditUnit = () => (dispatch) => dispatch({ type: EDIT_UNIT, data: null });

export const showEditDictionary = (data = {}) => (dispatch) => dispatch({ type: DICTIONARY, data });
export const closeEditDictionary = () => (dispatch) => dispatch({ type: DICTIONARY, data: null });

export const showTestingDialog = (data = true) => (dispatch) =>
  dispatch({ type: TESTING_DIALOG, data });
export const closeTestingDialog = () => (dispatch) =>
  dispatch({ type: TESTING_DIALOG, data: null });

export const showAddAttachment = (data = {}) => (dispatch) =>
  dispatch({ type: ADD_ATTACHMENT, data });
export const closeAddAttachment = () => (dispatch) =>
  dispatch({ type: ADD_ATTACHMENT, data: null });

export const showEditAttachment = (data = {}) => (dispatch) =>
  dispatch({ type: EDIT_ATTACHMENT, data });
export const closeEditAttachment = () => (dispatch) =>
  dispatch({ type: EDIT_ATTACHMENT, data: null });

export const showEditCredential = (data = {}) => (dispatch) =>
  dispatch({ type: EDIT_CREDENTIAL, data });
export const closeEditCredential = () => (dispatch) =>
  dispatch({ type: EDIT_CREDENTIAL, data: null });

export const showEditPlace = (data = true) => (dispatch) => dispatch({ type: EDIT_PLACE, data });
export const closeEditPlace = () => (dispatch) => dispatch({ type: EDIT_PLACE, data: null });

export const showAddPerson = (data = true) => (dispatch) => dispatch({ type: ADD_PERSON, data });
export const closeAddPerson = () => (dispatch) => dispatch({ type: ADD_PERSON, data: null });

export const showEditPerson = (data = {}) => (dispatch) =>
  dispatch({ type: EDIT_PERSON, data: data });
export const closeEditPerson = () => (dispatch) => dispatch({ type: EDIT_PERSON, data: null });

export const showEditName = (data = true) => (dispatch) => dispatch({ type: EDIT_NAME, data });
export const closeEditName = () => (dispatch) => dispatch({ type: EDIT_NAME, data: null });

export const showContactMethod = (data) => (dispatch) =>
  dispatch({ type: EDIT_CONTACT_METHOD, data });
export const closeContactMethod = (data) => (dispatch) =>
  dispatch({ type: EDIT_CONTACT_METHOD, data: null });

export const showPeopleCredentials = (data) => (dispatch) =>
  dispatch({ type: EDIT_PEOPLE_CREDENTIALS, data });
export const closePeopleCredentials = (data) => (dispatch) =>
  dispatch({ type: EDIT_PEOPLE_CREDENTIALS, data: null });

export const showPeopleEmployment = (data = true) => (dispatch) => {
  dispatch({ type: EDIT_PEOPLE_EMPLOYMENT, data });
};
export const closePeopleEmployment = () => (dispatch) =>
  dispatch({ type: EDIT_PEOPLE_EMPLOYMENT, data: null });
export const showPlaceEmployment = (data) => (dispatch) =>
  dispatch({ type: SHOW_PLACE_EMPLOYMENT, data });
export const closePlaceEmployment = (data) => (dispatch) =>
  dispatch({ type: SHOW_PLACE_EMPLOYMENT, data: null });

export const showAddressSOP = (data) => (dispatch) =>
  dispatch({ type: ADD_ADDRESS_SOP, data: data });
export const closeAddressSOP = () => (dispatch) => dispatch({ type: ADD_ADDRESS_SOP, data: null });

export const showPeopleSOP = (data) => (dispatch) => dispatch({ type: ADD_PEOPLE_SOP, data });
export const closePeopleSOP = () => (dispatch) => dispatch({ type: ADD_PEOPLE_SOP, data: null });

export const showPlaceSOP = (data) => (dispatch) => dispatch({ type: ADD_PLACE_SOP, data });
export const closePlaceSOP = () => (dispatch) => dispatch({ type: ADD_PLACE_SOP, data: null });

export const showWarrants = (data) => (dispatch) => dispatch({ type: SHOW_WARRANTS, data });
export const closeWarrants = () => (dispatch) => dispatch({ type: SHOW_WARRANTS, data: null });

export const showPersonDnaSample = (data = {}) => (dispatch) =>
  dispatch({ type: ADD_DNA_SAMPLE, data });
export const closePersonDnaSample = () => (dispatch) =>
  dispatch({ type: ADD_DNA_SAMPLE, data: null });

export const showPersonFeature = (data = {}) => (dispatch) =>
  dispatch({ type: ADD_PERSON_FEATURE, data });
export const closePersonFeature = () => (dispatch) =>
  dispatch({ type: ADD_PERSON_FEATURE, data: null });

export const showPersonPhotoDialog = (data = {}) => (dispatch) =>
  dispatch({ type: ADD_PERSON_PHOTO_ATTACHMENT, data });
export const closePersonPhotoDialog = () => (dispatch) =>
  dispatch({ type: ADD_PERSON_PHOTO_ATTACHMENT, data: null });

export const showPersonPhotoEdit = (data = {}) => (dispatch) =>
  dispatch({ type: EDIT_PERSON_PHOTO, data });
export const closePersonPhotoEdit = () => (dispatch) =>
  dispatch({ type: EDIT_PERSON_PHOTO, data: null });

export const showPersonNameDialog = (data) => (dispatch) =>
  dispatch({ type: PERSON_NAME_DIALOG, data });
export const closePersonNameDialog = () => (dispatch) =>
  dispatch({ type: PERSON_NAME_DIALOG, data: null });

export const showPersonRelatedRecordsDetail = (data = {}) => (dispatch) =>
  dispatch({ type: PERSON_RELATED_RECORDS, data });
export const closePersonRelatedRecordsDetail = () => (dispatch) =>
  dispatch({ type: PERSON_RELATED_RECORDS, data: null });

export const showPlaceAddressEdit = (data = {}) => (dispatch) =>
  dispatch({ type: PLACE_ADDRESS_EDIT, data });
export const closePlaceAddressEdit = () => (dispatch) =>
  dispatch({ type: PLACE_ADDRESS_EDIT, data: null });

export const showUnitResource = (data = {}) => (dispatch) =>
  dispatch({ type: UNIT_RESOURCE_ADD_EDIT, data });
export const closeUnitResource = () => (dispatch) =>
  dispatch({ type: UNIT_RESOURCE_ADD_EDIT, data: null });

export const showEditZone = (data = true) => (dispatch) => dispatch({ type: EDIT_ZONE, data });
export const closeEditZone = () => (dispatch) => dispatch({ type: EDIT_ZONE, data: null });

export const showZoneSop = (data = true) => (dispatch) => dispatch({ type: ZONE_SOP, data });
export const closeZoneSop = () => (dispatch) => dispatch({ type: ZONE_SOP, data: null });

export const showRotationHistory = (data = true) => (dispatch) =>
  dispatch({ type: ROTATION_HISTORY, data });
export const closeRotationHistory = () => (dispatch) =>
  dispatch({ type: ROTATION_HISTORY, data: null });
export default function reducer(state = defaultDialogs, action) {
  switch (action.type) {
    case SET_ADDOUTSERVICEUNIT:
      return { ...state, addOutserviceUnit: action.show };
    case ADD_MULTI_SELECTABLE_UNIT_DIALOG:
      return { ...state, addMultiSelectableUnit: action.data };
    case ADD_PAGE:
      return { ...state, addPage: action.data };
    case UNIT_STATUS:
      return { ...state, unitStatus: action.data };
    case ADD_EVENT:
      return { ...state, addEvent: action.data };
    case EDIT_EVENT:
      return { ...state, editEvent: action.data };
    case SOPS:
      return { ...state, sops: action.data };
    case ADD_SOP:
      return { ...state, editSop: action.data };
    case EDIT_SOP:
      return { ...state, editSop: action.data };
    case EDIT_PAGE_GROUP:
      return { ...state, editPageGroup: action.data };
    case EDIT_PAGE_RECIPIENT:
      return { ...state, editPageRecipient: action.data };
    case EDIT_PAGE_TEMPLATE:
      return { ...state, editPageTemplate: action.data };
    case DISPATCH_RECOMMENDATIONS:
      return { ...state, dispRecommendation: action.ptsEventID };
    case UNIT_PROPS:
      return { ...state, unitProps: action.ptsUnitID };
    case NEW_UNIT_STATUS:
      return { ...state, newUnitStatus: action.payload };
    case MULTIPLE_UNIT_STATUS_CHANGE:
      return { ...state, multipleUnitStatusChange: action.payload };
    case UNIT_ZONE:
      return { ...state, unitZone: action.ptsUnitID };
    case OPEN_SETTINGS:
      return { ...state, settings: true };
    case CLOSE_SETTINGS:
      return { ...state, settings: null };
    case DISPLAY_DATA:
      return { ...state, displayData: action.data };
    case ADD_BULLETIN:
      return { ...state, addBulletin: action.data };
    case EDIT_BULLETIN:
      return { ...state, editBulletin: action.data };
    case ADDRESS:
      return { ...state, address: action.data };
    case BULLETIN_DETAILS:
      return { ...state, bulletinDetails: action.data };
    case REPORT:
      return { ...state, report: action.data };
    case ROTATIONS:
      return { ...state, rotations: action.data };
    case EDIT_VEHICLE:
      return { ...state, editVehicle: action.data };
    case ADD_VEHICLE:
      return { ...state, addVehicle: action.data };
    case VEHICLE_EDIT_REG:
      return { ...state, vehicleEditReg: action.data };
    case TOWING:
      return { ...state, towing: action.data };
    case ABOUT:
      return { ...state, about: action.data };
    case ADD_Unit:
      return { ...state, addUnit: action.data };
    case EDIT_UNIT:
      return { ...state, editUnit: action.data };
    case DICTIONARY:
      return { ...state, dictionary: action.data };
    case TESTING_DIALOG:
      return { ...state, testing: action.data };
    case ADD_ATTACHMENT:
      return { ...state, addAttachment: action.data };
    case EDIT_ATTACHMENT:
      return { ...state, editAttachment: action.data };
    case EDIT_CREDENTIAL:
      return { ...state, editCredential: action.data };
    case EDIT_PLACE:
      return { ...state, editPlace: action.data };
    case ADD_PERSON:
      return { ...state, addPerson: action.data };
    case EDIT_PERSON:
      return { ...state, editPerson: action.data };
    case EDIT_NAME:
      return { ...state, editName: action.data };
    case EDIT_CONTACT_METHOD:
      return {
        ...state,
        editContactMethod: action.data,
      };
    case EDIT_PEOPLE_CREDENTIALS:
      return {
        ...state,
        editPeopleCredentials: action.data,
      };
    case EDIT_PEOPLE_EMPLOYMENT:
      return {
        ...state,
        editPeopleEmployment: action.data,
      };
    case EDIT_PEOPLE_ADDRESS:
      return {
        ...state,
        editPeopleAddress: action.data,
      };
    case ADD_ADDRESS_SOP:
      return {
        ...state,
        addAddressSOP: action.data,
      };
    case ADD_PEOPLE_SOP:
      return { ...state, addPeopleSOP: action.data };
    case ADD_PLACE_SOP:
      return { ...state, addPlaceSOP: action.data };
    case SHOW_PLACE_EMPLOYMENT:
      return { ...state, editPlaceEmployment: action.data };
    case SHOW_WARRANTS:
      return { ...state, warrants: action.data };
    case ADD_DNA_SAMPLE:
      return { ...state, personDnaSample: action.data };
    case ADD_PERSON_FEATURE:
      return { ...state, personFeature: action.data };
    case ADD_PERSON_PHOTO_ATTACHMENT:
      return { ...state, personPhotoAttachment: action.data };
    case EDIT_PERSON_PHOTO:
      return { ...state, editPersonPhoto: action.data };
    case PERSON_NAME_DIALOG:
      return { ...state, personName: action.data };
    case PERSON_RELATED_RECORDS:
      return { ...state, personRelatedRecords: action.data };
    case PLACE_ADDRESS_EDIT:
      return { ...state, editPlaceAddress: action.data };
    case UNIT_RESOURCE_ADD_EDIT:
      return { ...state, addEditUnitResource: action.data };
    case EDIT_ZONE:
      return { ...state, editZone: action.data };
    case ZONE_SOP:
      return { ...state, zoneSop: action.data };
    case ROTATION_HISTORY:
      return { ...state, editRotation: action.data };
    default:
      break;
  }
  return state;
}
