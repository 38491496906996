import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from 'components/Dialog';
import SOP from './SOP';
import { updateSOPs } from 'reducers/SopsReducer';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { hideSops } from 'reducers/DialogsReducer';
import { saveEventNote } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  notify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialog: {
    '& h5': {
      width: 600,
      maxWidth: '100%',
      margin: '1rem 0 0.75rem',
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    '& hr': {
      borderColor: theme.palette.primary.main,
    },
  },
  questions: {
    width: '100%',
  },
}));

function Sops(props) {
  const classes = useStyles();
  const { ptsEventID, events, CopySOPAnswersToNotes, fromEventClose } = props;
  const [sops, setSops] = useState([]);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const event = events.find((ev) => ev.ptsEventID === ptsEventID);
    const sops = event?.sops ? event.sops : [];
    setSops(sops);
    // eslint-disable-next-line
  }, []);

  const onClose = () => {
    if (updated && !window.confirm('Are you sure you want to discard all changes?')) return;
    props.hideSops();
  };

  const update = (no) => (sop) => {
    const newSops = [...sops];
    newSops[no] = sop;
    setSops(newSops);
    !updated && setUpdated(true);
  };

  const onSave = () => {
    const data = sops.map((sop) => ({ ...sop, ptsEventID: props.ptsEventID }));
    if (CopySOPAnswersToNotes) addSopNote(data);
    props.updateSOPs(data);
    props.hideSops();
  };

  const addSopNote = (data) => {
    let Comment = 'SOP Answers Updated \n--------------------------------------- \n';
    data.forEach((sop, idx) => {
      if (idx) Comment += '\n--------------------------------------- \n';
      const { SOPID, DispatcherQuestions } = sop;
      Comment += `Title: ${SOPID} \n`;
      DispatcherQuestions.forEach((q) => {
        const { type, text, value } = q;
        Comment += `${text}: `;
        switch (type) {
          case 'Question':
            Comment += `${value} `;
            break;
          case 'CheckBox':
            Comment += value ? 'True ' : 'False ';
            break;
        }
        Comment += '\n';
      });
    });
    try {
      saveEventNote({ Comment }, ptsEventID);
    } catch (err) {
      handleError(err);
    }
  };

  const actions = (
    <>
      <Button
        color="primary"
        variant="contained"
        autoFocus
        onClick={fromEventClose ? onClose : onSave}>
        {fromEventClose ? (
          'Ok'
        ) : (
          <>
            <SaveIcon /> Save
          </>
        )}
      </Button>
      <Button color="primary" onClick={onClose}>
        <CloseIcon /> Cancel
      </Button>
    </>
  );

  return (
    <Dialog
      onClose={onClose}
      title="SOPs"
      maxWidth="lg"
      className={classes.dialog}
      actions={actions}
      height={640}
      close>
      {sops.map((sop, idx) => (
        <SOP
          key={sop.ptsSOPID}
          sop={sop}
          no={idx}
          update={update(idx)}
          fromEventClose={fromEventClose}
        />
      ))}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    CopySOPAnswersToNotes: state.config?.options?.EventAutomation?.CopySOPAnswersToNotes,
    events: state.events,
  };
};

export default connect(mapStateToProps, {
  updateSOPs,
  hideSops,
  handleError,
})(Sops);
