import React, { useState, useEffect, Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Accordion from '@material-ui/core/Accordion';
import { notify, showCustomMsgBox, hideCustomMsgBox } from 'reducers/NotifierReducer';
import { dispRecommendations, showAddressDialog, showSops } from 'reducers/DialogsReducer';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from 'components/Tooltip';
import { areObjEqual, asyncForEach } from 'utils/functions';
import { displayTime, displayDateTime } from 'reducers/TimeReducer';
import HtmlTooltip from 'components/HtmlTooltip';
import {
  assignUnitToEvent,
  setIsStatusUpdateFailed,
  updateUnitStatusPromise,
} from 'reducers/UnitStatusReducer';
import EventAgencies from './EventAgencies';
import EventDropdownMenu from './EventDropdownMenu';
import EventStatusMenu from './EventStatusMenu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';
import EventAvatar from 'components/EventAvatar';
import EventStatus from 'components/EventStatus';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Badge from '@material-ui/core/Badge';
import { getEventLocationAddresses } from 'reducers/EventsReducer';
import EventTooltip from 'components/EventTooltip';
import { setExpanded, setTab } from 'reducers/EventSortingReducer';
import EventTabMenu from './EventTabMenu';
import EventTabDetails from './EventTabDetails';
import defaultMenuOptions from './defaultMenuOptions';
import { mapAction } from 'reducers/MapReducer';
import { getCoords } from 'utils/mapFunctions';
import { getRequiredDispositions } from 'reducers/EventsReducer';
import EventHotKeys from './EventHotKeys';
import { handleError } from 'reducers/ErrorReducer';
import { copyEvent } from 'reducers/EventsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const infoWidth = 230;
const statusWidth = 100;
const timeWidth = 75;

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 5,
    transition: 'background 0.1s',
    borderBottom: `1px solid ${theme.card.hr}`,
    borderRadius: `${theme.shape.borderRadius} !important`,
    borderTopWidth: 5,
    borderTopStyle: 'solid',
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      width: '100%',
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
    '& .MuiAccordionSummary-root': {
      width: '100%',
      '&.Mui-expanded': {
        minHeight: 48,
      },
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:hover $dragHandle': {
      opacity: 0.35,
    },
  },
  expanded: {
    marginBottom: '5px !important',
  },
  expandIcon: {
    position: 'absolute',
    left: 4,
    top: 7,
  },
  dragging: {
    boxShadow: `0 0 0 5px ${theme.palette.secondary.main}`,
  },
  summaryWrap: {
    position: 'relative',
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 0,
  },
  summary: {
    flexDirection: 'row-reverse',
    paddingLeft: theme.spacing(4),
    justifyContent: 'flex-end',
    width: '100%',
  },
  summaryContent: {
    boxSizing: 'border-box',
    width: '100%',
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sumContentLeft: {
    flex: 1,
    overflow: 'hidden',
    height: 54,
    marginRight: theme.spacing(2),
    '&:hover': {
      overflow: 'auto',
    },
  },
  leftWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  sumContentRight: {
    display: 'flex',
    alignItems: 'center',
    flex: 0,
    width: 200,
  },
  eventMenu: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  status: {
    width: statusWidth,
    flex: `0 0 ${statusWidth}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
    },
  },
  updateTime: {
    width: timeWidth,
    flex: `0 0 ${timeWidth}px`,
    padding: '0 5px',
    textAlign: 'center',
  },
  reccomendations: {
    fontSize: 12,
    fontWeight: 500,
  },
  reqAction: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(6),
  },
  infoIco: {
    opacity: 0.5,
    transition: 'opacity 0.3s',
    margin: '0 10px',
    '&:hover': {
      opacity: 1,
    },
  },
  historyIco: {
    display: 'inline-block',
    textAlign: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    fontSize: 13,
    lineHeight: 1.5,
    opacity: 0.5,
    transition: 'opacity 0.3s',
    margin: '0 10px',
    '&:hover': {
      opacity: 1,
    },
  },
  historyIcoInactive: {
    display: 'inline-block',
    textAlign: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    color: '#fff',
    fontSize: 13,
    lineHeight: 1.5,
    margin: '0 10px',
  },
  info: {
    width: infoWidth,
    flex: `0 0 ${infoWidth}px`,
    marginRight: 10,
    '& > div:first-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1,
    },
  },
  details: {
    flexDirection: 'column',
    padding: 0,
    background: theme.card.bg,
  },
  dragHandle: {
    position: 'absolute',
    display: 'flex',
    opacity: 0,
    transition: 'opacity 0.3s',
    top: 1,
    left: 3,
    right: 3,
    height: 11,
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 3,
    zIndex: 10,
    overflow: 'hidden',
    '& > div': {
      borderTop: `1px dotted ${theme.palette.primary.main}`,
    },
  },
  active: {
    backgroundColor: theme.colors.active,
  },
  locationInfo: {
    width: '100%',
    height: 30,
    lineHeight: '1.25em',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    fontSize: 12,
    marginTop: 3,
  },
  evAvatar: {
    background: theme.card.bg2,
    borderRadius: '15%',
    fontSize: '1.1em',
  },
}));

function Event(props) {
  const classes = useStyles();
  const { context, colorPalette, pinned, selected, unitList, open, tab } = props;
  const dispatch = useDispatch();
  const {
    EventID,
    CallTypeDescription,
    Status,
    FullLocationAddress,
    ptsEventID,
    assignedUnits,
    ReceiveDate,
    UpdateDate,
    UnitStatuses,
    EventChangeDate,
    RequestedAction,
    sops,
    EventRouting,
    PlaceName,
    LocationDescription,
    AddressHistoryCount,
    LatitudeDegree,
    LongitudeDegree,
    CrossStreet,
  } = props.ev;

  const EventColors = colorPalette.Events;
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);
  const [dragging, setDragging] = useState(false);
  const [dragged, setDragged] = useState(false);
  const [unfoldSubscription, setUnfoldSubscription] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [reqDispositions, setReqDispositions] = useState({
    reqDispAgencies: [],
    reqMergedDisp: [],
  });
  const [dispositionWarning, setDispositionWarning] = useState(false);
  const [lastChange, setLastChange] = useState(null);
  const statusMenuRef = useRef(null);
  const draggingTimeRef = useRef(0);
  const statusColor = EventColors[Status];
  const mountedRef = useRef(true);

  useEffect(() => {
    if (context.current.unfoldSubject) {
      const subscription = context.current.unfoldSubject.subscribe((unfold) => {
        props.setExpanded(ptsEventID, unfold);
      });
      setUnfoldSubscription(subscription);
    }
    return () => {
      unfoldSubscription && unfoldSubscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newReqDispositions = getRequiredDispositions(ptsEventID);
    if (areObjEqual(reqDispositions, newReqDispositions)) return;
    setReqDispositions(newReqDispositions);
    // eslint-disable-next-line
  }, [EventRouting]);

  const showDispRecommendations = (ev) => {
    ev.stopPropagation();
    props.dispRecommendations(ptsEventID);
  };

  const showAddNote = (ev) => {
    ev.stopPropagation();
    changeTab('Notes');
  };

  const renderTime = () => {
    const statuses = UnitStatuses?.length ? UnitStatuses : [];
    const getStatuses = () => {
      return (
        <Fragment>
          <h4>Units History</h4>
          <table>
            <tbody>
              {statuses.map((status, idx) => {
                return (
                  <tr key={idx}>
                    <td>{status.Unit}</td>
                    <td>{status.UnitStatus}</td>
                    <td>{displayDateTime(status.Occurred)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Fragment>
      );
    };

    const Html = (
      <div>
        <h4>Event</h4>
        <table>
          <tbody>
            <tr>
              <th>Created</th>
              <td>{displayDateTime(ReceiveDate)}</td>
            </tr>
            {UpdateDate !== undefined && (
              <tr>
                <th>Updated</th>
                <td>{displayDateTime(UpdateDate)}</td>
              </tr>
            )}
          </tbody>
        </table>
        {UnitStatuses !== null && getStatuses()}
      </div>
    );

    return (
      <HtmlTooltip title={Html}>
        <span className={classes.updateTime}>{displayTime(EventChangeDate)}</span>
      </HtmlTooltip>
    );
  };

  const renderEvAndLocation = () => {
    const crossStreet = CrossStreet || '';
    const locationDescription = LocationDescription || '';
    const location =
      locationDescription && crossStreet
        ? `${locationDescription}, ${crossStreet}`
        : locationDescription + crossStreet;
    const locationInfo = (
      <>
        {PlaceName !== null && <>{PlaceName} - </>}
        {FullLocationAddress}
        {Boolean(location && FullLocationAddress) ? <> ({location})</> : ''}
        {Boolean(location && !FullLocationAddress) ? location : ''}
      </>
    );

    return (
      <div className={classes.info}>
        <EventTooltip event={props.ev}>
          <div>
            <strong>{EventID}</strong> - <span>{CallTypeDescription}</span>
          </div>
        </EventTooltip>
        <Tooltip title={locationInfo}>
          <div className={classes.locationInfo}>{locationInfo}</div>
        </Tooltip>
      </div>
    );
  };

  const renderSops = () => {
    const len = sops.length;
    return (
      <>
        {len > 0 && (
          <Tooltip title="Show SOPs">
            <IconButton color="primary" onClick={showSopsDetails}>
              <Badge badgeContent={len} color="error" overlap="circle">
                <AssignmentLateIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {len === 0 && (
          <Tooltip title="Show SOPs">
            <span>
              <IconButton disabled>
                <AssignmentLateIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  const renderDispatchRecommendations = () => {
    return (
      <div>
        <Tooltip title="Dispatch recommendations">
          <span>
            <IconButton onClick={showDispRecommendations} color="primary">
              <AssignmentIndIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderAddNote = () => {
    return (
      <div>
        <Tooltip title="Add note">
          <span>
            {' '}
            <IconButton onClick={showAddNote} color="primary">
              <NoteAddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderAddressHistory = () => {
    return AddressHistoryCount > 0 ? (
      <Tooltip title="Address History">
        <span>
          <IconButton color="primary" onClick={showAddressHistory}>
            {AddressHistoryCount > 1 ? (
              <Badge badgeContent={AddressHistoryCount - 1} color="secondary" overlap="circle">
                <AssignmentIcon />
              </Badge>
            ) : (
              <AssignmentIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
    ) : (
      <IconButton color="primary" disabled>
        <AssignmentIcon />
      </IconButton>
    );
  };

  const showSopsDetails = (ev) => {
    ev.stopPropagation();
    props.showSops(ptsEventID);
  };

  const renderReqAction = () => {
    const ReqActions = props.RequestedActions;
    let Description = '';
    if (ReqActions.length > 0) {
      const evType = ReqActions.find((type) => type.Code === RequestedAction);
      if (evType) Description = 'Req. action: ' + evType.Description;
    }
    if (!RequestedAction) return '';

    return (
      <Tooltip title={Description}>
        <div className={classes.reqAction}>{RequestedAction}</div>
      </Tooltip>
    );
  };

  const renderAgencies = () => {
    if (!EventRouting) return '';
    return (
      <EventAgencies
        agencies={EventRouting}
        colorPalette={colorPalette}
        units={assignedUnits}
        history={UnitStatuses}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          props.setTab(ptsEventID, 'Units');
          props.setExpanded(ptsEventID, true);
        }}
      />
    );
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();
    clearTimeout(draggingTimeRef.current);
    if (!dragging) {
      const draggedData = JSON.parse(localStorage.getItem('dragNdrop'));
      if (!draggedData) return;
      setDragging(true);
      if (areObjEqual(dragged, draggedData)) return;
      setDragged(draggedData);
    }
    //console.log('assigned unit to event drag over : ', new Date().getTime() - time);
  };

  const handleDragLeave = (ev) => {
    clearTimeout(draggingTimeRef.current);
    draggingTimeRef.current = setTimeout(() => {
      setDragging(false);
    }, 50);
  };

  const handleDrop = async (ev) => {
    const time = new Date().getTime();
    if (!dragged) return;
    clearTimeout(draggingTimeRef.current);
    setDragging(false);
    const draggedUnits = dragged?.units || [];
    const noOfUnits = draggedUnits.length || 0;
    if (noOfUnits > 1) {
      let message = 'Are you sure you want to dispatch ';
      draggedUnits.forEach((ptsUnitID, idx) => {
        if (idx) message += ' and ';
        const unit = unitList.find((unit) => unit.ptsUnitID === ptsUnitID);
        if (unit) {
          message += `${unit.Unit} (${unit.AgencyID})`;
        }
      });
      message += ` to Event ${EventID}?`;
      if (!confirm(message)) return;
    }

    await asyncForEach(dragged?.units, async (ptsUnitID) => {
      const unitExists = !!assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
      if (!unitExists) {
        try {
          await updateUnitStatusPromise({ ptsUnitID }, ptsEventID);
          dispatch(notifyDataUpdate({ type: 'unit-status' }));
        } catch (error) {
          dispatch(setIsStatusUpdateFailed(true));
        }
      }
    });
    localStorage.removeItem('dragNdrop');
  };

  const onMenuClick = (ev) => {
    ev.stopPropagation();
    if (!open) props.setExpanded(ptsEventID, true);
  };

  const showMenu = (ev) => {
    ev.stopPropagation();
    setMenuAnchor(ev.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const showStatusMenu = (ev) => {
    ev.stopPropagation();
    setStatusMenuOpen(true);
  };

  const closeStatusMenu = () => {
    setStatusMenuOpen(false);
  };

  const toggleExpand = (ev) => {
    ev.stopPropagation();
    props.setExpanded(ptsEventID, !open);
  };

  const showAddressHistory = async (ev) => {
    ev.stopPropagation();
    try {
      const ptsAddressID = await getEventLocationAddresses(ptsEventID);
      if (!mountedRef.current) return;
      if (ptsAddressID) {
        props.showAddressDialog({ ptsAddressID, tab: 'history', EventID });
      } else {
        props.notify('Cannot find location history for selected event.', 'error');
      }
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const changeTab = (tab) => {
    setLastChange(tab);
    props.setTab(ptsEventID, tab);
    props.setExpanded(ptsEventID, true);
  };

  const toggleMenuOptions = (key) => {
    const newMenuOptions = { ...menuOptions };
    newMenuOptions[key].sort = newMenuOptions[key].sort === 'ASC' ? 'DESC' : 'ASC';
    setMenuOptions(newMenuOptions);
  };

  const onClick = (ev) => {
    if (ev.detail === 1) {
      context.current.selectEvent(ev, ptsEventID);
      if (LatitudeDegree || LongitudeDegree) {
        props.mapAction({ type: 'zoom-and-center', ...getCoords(props.ev) });
      }
    } else {
      onDblClick(ev);
    }
  };

  const onDblClick = (ev) => {
    context.current.editEvent(ptsEventID);
  };

  const dispNotMet = () => {
    props.setTab(ptsEventID, 'Dispositions');
    props.setExpanded(ptsEventID, true);
    setDispositionWarning(true);
  };

  const handleCopyEvent = async () => {
    try {
      const newEvent = await copyEvent(ptsEventID);
      //props.notify(`Created event: ${newEvent.EventID}`, 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  return (
    <>
      <Accordion
        expanded={open}
        onClick={onClick}
        className={clsx(
          classes.card,
          dragging ? classes.dragging : null,
          open ? classes.expanded : null,
          selected ? classes.active : null
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{ borderTopColor: statusColor }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          className={classes.summaryWrap}
          classes={{ expandIcon: classes.expandIcon }}
          IconButtonProps={{ onClick: toggleExpand }}>
          <div className={classes.summary}>
            <div className={classes.summaryContent}>
              <div className={clsx('thin-horizontal-scrollbar', classes.sumContentLeft)}>
                <div className={classes.leftWrap}>
                  <EventAvatar event={props.ev} className={classes.evAvatar} />
                  {renderEvAndLocation()}
                  <EventStatus event={props.ev} menuRef={statusMenuRef} onClick={showStatusMenu} />
                  {renderTime()}
                  {renderSops()}
                  {renderAddressHistory()}
                  {renderDispatchRecommendations()}
                  {renderAddNote()}
                  {renderAgencies()}
                  {renderReqAction()}
                </div>
              </div>
              <div className={classes.sumContentRight}>
                {pinned && (
                  <Tooltip title="Unpin">
                    <span>
                      <IconButton onClick={context.current.togglePin}>
                        <AiFillPushpin />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {!pinned && (
                  <Tooltip title="Pin">
                    <span>
                      <IconButton onClick={context.current.togglePin}>
                        <AiOutlinePushpin />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                <Tooltip title="More">
                  <span>
                    <IconButton aria-label="event-menu" aria-haspopup="true" onClick={showMenu}>
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={classes.eventMenu} onClick={onMenuClick}>
            <EventTabMenu
              value={tab}
              setValue={changeTab}
              event={props.ev}
              menuOptions={menuOptions}
              toggleMenuOptions={toggleMenuOptions}
              reqDispositions={reqDispositions}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details} onClick={(ev) => ev.stopPropagation()}>
          {open && (
            <EventTabDetails
              event={props.ev}
              menuOptions={menuOptions}
              dispositionWarning={dispositionWarning}
              setValue={changeTab}
              lastChange={lastChange}
            />
          )}
        </AccordionDetails>
      </Accordion>
      {menuAnchor !== null && (
        <EventDropdownMenu
          menuAnchor={menuAnchor}
          closeMenu={closeMenu}
          event={props.ev}
          copyEvent={handleCopyEvent}
          reqDispositions={reqDispositions}
          context={context}
          dispNotMet={dispNotMet}
        />
      )}
      {statusMenuOpen && (
        <EventStatusMenu
          anchorEl={statusMenuRef.current}
          open={statusMenuOpen}
          closeMenu={closeStatusMenu}
          onClose={closeStatusMenu}
          reqDispositions={reqDispositions}
          dispNotMet={dispNotMet}
          event={props.ev}
        />
      )}
      {selected && (
        <EventHotKeys
          reqDispositions={reqDispositions}
          dispNotMet={dispNotMet}
          event={props.ev}
          copyEvent={handleCopyEvent}
          setTab={changeTab}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    RequestedActions: state.dictionary.RequestedActions,
    theme: state.theme,
    colorPalette: state.config.colorPalette,
  };
};

export default connect(mapStateToProps, {
  assignUnitToEvent,
  notify,
  showCustomMsgBox,
  hideCustomMsgBox,
  dispRecommendations,
  showSops,
  showAddressDialog,
  setExpanded,
  setTab,
  mapAction,
  copyEvent,
  handleError,
  notifyDataUpdate,
  setIsStatusUpdateFailed,
})(Event);
